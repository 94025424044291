import React, {useState} from 'react'
import './AdmEventCard.scss'

import formatarHorario from '../../utils/formatarHorario';

import { FiCheck, FiX, FiEdit } from 'react-icons/fi';

export default function AdmEventCard({event, att, reagendar}) {

  const [ attend, setAttend ] = useState(event.attendance)

  function formatarData(data){
    if(event){
      return (data.split('-').reverse().join('/'));
    }
  }

  function manage(a){
    setAttend(a)

    att(event.id, a)
  }

  return (
    <div className='eventCard'>
      { event.vacation_id&&
        <>
          <div className='eventInfo'>
            <div className='vacationTitle'>Ferias</div>
            <div className='vacationDate'>{formatarData(event.month_day)}</div>
          </div>
        </>
      }
      { event.type=='national'&&
        <>
          <div className='eventInfo'>
            <div className='vacationTitle'>Feriado</div>
            <div className='vacationTitle'>{event.name}</div>
            <div className='vacationDate'>{formatarData(event.date)}</div>
          </div>
        </>
      }
      { event.user_course&&
        <>
          { (attend === null && (event.user_course.deleted_at === null && event.user_course.user.deleted_at === null))&&
            <button onClick={() => reagendar(event)} className='reagendar'><FiEdit size={20} color='black'/></button>
          }
          <div className='eventInfo'>
            <div className='student'>{event.user_course.user.name}</div>
            <div className='time'>{formatarHorario(event.hour.from_hour)}</div>
            <div className='course'>{event.user_course.course.name}</div>
            <div className='title'>{formatarData(event.month_day)}</div>
            {(event.user_course.deleted_at || event.user_course.user.deleted_at)&&
            <div className='cancelado'>{event.user_course.user.deleted_at? 'Matricula':'Aula'} cancelada</div>
          }
          </div>
          
          { (event.user_course.deleted_at === null && event.user_course.user.deleted_at === null)&& 
            <div className='attendance'>
              <button onClick={() => manage(true)} className={`attBtn accept ${attend === true&& 'attTrue'}`}><FiCheck size={20} color={attend === true?'#FFFFFF':'#057dcd'}/></button>
              <button onClick={() => manage(false)} className={`attBtn refuse ${attend === false&& 'attFalse'}`}><FiX size={20} color={attend === false?'#FFFFFF':'#ff0000'}/></button>
            </div>
          }

          
        </>

      }
    </div>
  )
}
