import axios from 'axios'

const feriados = axios.create({
  baseURL: 'https://brasilapi.com.br/api/feriados/v1'
})

feriados.defaults.headers['Content-Type'] = 'application/json';
feriados.defaults.headers['Accept'] = 'application/json';



export default feriados