import React, { useState } from 'react'
import diaSemana from '../../utils/diaSemana'
import formatarHorario from '../../utils/formatarHorario'

import './NovoAlunoItem.scss'

export default function NovoAlunoItem({i, aluno, auth}) {

  const [ dropDown, setDropDown ] = useState(false)

  return (
    <div className={`novoAlunoCard ${ dropDown&& 'novoAlunoCardOpen' }`}>
      <div onClick={() => setDropDown(!dropDown)} className='header'>
        <div className='headerInfo'>
          <div className='headerValue'>{aluno.user_courses[0].course.name}</div>
          <div className='headerValue'>{aluno.name}</div>
        </div>
      </div>
      <div className={dropDown? 'body bodyOpen' : 'body'}>
        <div className='data'>
          <div className='dataInfo'>
            <div className='dataValueLeft'>{aluno.name}</div>
            <div className='dataValueRight'>{aluno.user_courses[0].course.name}</div>
          </div>
          <div className='dataInfo'>
            <div className='dataValueLeft'>{aluno.document}</div>
            <div className='dataValueRight'>{aluno.user_courses[0].course.qty_class} Aula(s)</div>
          </div>
          <div className='dataInfo'>
            <div className='dataValueLeft'>{diaSemana(aluno.user_courses[0].hour.teacher_working_hour.week_day)}</div>
            <div className='dataValueRight'>{formatarHorario(aluno.user_courses[0].hour.from_hour)}</div>
          </div>
        </div>
        <div className='aprovar'>
          <div className='aprovarText'>Aprovar Inscrição?</div>
          <button onClick={() => auth(aluno.id, true, i)}>SIM</button>
          <button onClick={() => auth(aluno.id, false, i)}>NÃO</button>
        </div>
      </div>
    </div>
  )
}
