import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';

import './ResetPassword.scss'

import Loading from '../../components/Loading'
import logo from '../../assets/logo-black.png'

import api from '../../service/api';
import ErrorMessage from '../../components/ErrorMessage';
import ErrorBox from '../../components/ErrorBox';
import PasswordInput from '../../components/PasswordInput';
import SnackAlert from '../../components/Alertas/SnackAlert';

export default function ResetPassword() {
  const navigate = useNavigate()

  const { token } = useParams()
  
  const [ loading, setLoading ] = useState(false)
  const [ errSenha, setErrSenha ] = useState(false)
  const [ openSnack, setOpenSnack ] = useState(false)
  const [ msgErr, setMsgErr ] = useState('')
  const [ snackType, setSnackType ] = useState('')

  const initialValues = {
    password: '',
    confirmPassword: '',
  }

  const validationSchema = Yup.object({
    password: Yup.string().required("Campo Senha é obrigatório"),
    confirmPassword: Yup.string().required("Confirmar Senha é obrigatório")
  })

  async function handleAlterarSenha(values){
    setLoading(true)
    setErrSenha(false)

    try{
      if(values.password === values.confirmPassword){
        let payload = {
          password: values.password
        }

        let res = await api.post('/reset-password', payload, {
          headers: {
            token: token
          }
        })

        if(res.status === 200){
          setSnackType('success')
          setMsgErr(res.data.message)
          setOpenSnack(true)
          navigate('/')
        }
      } else {
        setErrSenha(true)
      }
    } catch (err){
      setSnackType('error')
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div id='reset-container'>
      { loading&&
        <Loading />
      }
      <div className='headerReset'>
        <h3>RECURERAR SENHA</h3>
      </div>
      <div className='imgContainer'>
        <img src={logo} alt='logo acordeon'/>
      </div>
      <div className='formDiv'>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={values => handleAlterarSenha(values) }
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <div className='forgotText'>Digite sua nova senha</div>

              <PasswordInput id={'password'} name={'password'} holder={'Senha'} value={props.values.password} onChange={props.handleChange}/>
              {props.errors.password&& props.touched.password&& <ErrorMessage error={props.errors.password} />}

              <PasswordInput id={'confirmPassword'} name={'confirmPassword'} holder={'Confirmar Senha'} value={props.values.confirmPassword} onChange={props.handleChange}/>
              {props.errors.confirmPassword&& props.touched.confirmPassword&& <ErrorMessage error={props.errors.confirmPassword} />}
              
              {errSenha&& <ErrorBox err={'As senhas não correspondem'}/>}
              <Button className='general-button' variant="outlined" type='submit'>Salvar</Button>

            </form>
          )}
        </Formik>
      </div>
      <SnackAlert open={openSnack} setOpen={setOpenSnack} type={snackType} msg={msgErr} />
    </div>
  )
}
