import axios from 'axios'

const api = axios.create({
  //baseURL: process.env.REACT_APP_LOCAL
  baseURL: process.env.REACT_APP_API_URL
})

api.defaults.headers['Content-Type'] = 'application/json';
api.defaults.headers['Accept'] = 'application/json';

api.interceptors.request.use( async(request) => {
  let token = sessionStorage.getItem('seshToken');
  console.log('tkn: ', token);
  if( token ){
    request.headers['Authorization'] = `Bearer ${token}`
  }
  return request;
}, (error)=> {
  Promise.reject(error)
});

export default api