import React from 'react'
import Modal from '@mui/material/Modal'
import { FiX } from 'react-icons/fi';

import './DetalhesCurso.scss';

export default function DetalhesCurso({modal, modalClose, curso}) { 
  
  return (
    <Modal
      open={modal}
      onClose={modalClose}
    >
      <div id='detalhescurso-container'>
        <div className='box'>
          <div className='header'>
            Detalhes
            <button className='close' onClick={() => modalClose(false)}><FiX size={25} color='gray' /></button>
          </div>

          <div className='titulocurso'>
            {curso.name}
          </div>
          <div className='qtdClass'>
            {curso.qty_class} aulas
          </div>

          <div className='desc'>
            Descrição: 
            <p className='descText'>
              {curso.desc}
            </p>
          </div>
        </div>
      </div>
    </Modal>
  )
}
