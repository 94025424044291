import React from 'react'

import DatePicker, { setDefaultLocale } from "react-datepicker";
import {ptBR} from 'date-fns/esm/locale'

import './DateSelector.scss';
setDefaultLocale(ptBR)

export default function DateSelector({date, setDate, place}) {

  return (
    <DatePicker placeholderText={place} locale={ptBR} dateFormat={"dd/MM/yyyy"} selected={date} onChange={(d) => setDate(d)}/>
  );
}