import React from 'react'

import { Routes, Route } from 'react-router-dom'

// ==== iniciais
import Login from './pages/Login'
import CadastroDados from './pages/CadastroDados'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import AlterarHorario from './pages/AlterarHorario'
// ==== user
import HomeUser from './pages/HomeUser'
import EditarPerfil from './pages/EditarPerfil'
import MeusCursos from './pages/MeusCursos'
import NovoCurso from './pages/NovoCurso'
// ==== adm
import HomeAdm from './pages/HomeAdm'
import DefinirHorarios from './pages/DefinirHorarios'
import HorariosAdm from './pages/HorariosAdm'
import FeriasAdm from './pages/FeriasAdm'
import RegistroCurso from './pages/RegistroCurso'
import EditAviso from './pages/EditAviso'
import AceitarAluno from './pages/AceitarAluno'
import AceitarInscricao from './pages/AceitarInscricao'
import ListarAlunos from './pages/ListarAlunos'
import ListaCursos from './pages/ListaCursos'

export default function MainRoutes(){
  return(
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/Cadastro/Dados" element={<CadastroDados />} />
      <Route path="/ForgotPassword" element={<ForgotPassword />} />
      <Route path="/AlterarHorario" element={<AlterarHorario />} />
      <Route path="/ResetPassword/:token" element={<ResetPassword />} />

      <Route path="/HomeUser" element={<HomeUser />} />
      <Route path="/MeusCursos" element={<MeusCursos />} />
      <Route path="/NovoCurso" element={<NovoCurso />} />
      <Route path="/Perfil" element={<EditarPerfil />} />

      <Route path="/HomeAdm" element={<HomeAdm />} />
      <Route path="/DefinirHorarios" element={<DefinirHorarios />} />
      <Route path="/HorariosAdm" element={<HorariosAdm />} />
      <Route path="/FeriasAdm" element={<FeriasAdm />} />
      <Route path="/Cursos" element={<ListaCursos/>} />
      <Route path="/Cursos/Cadastro" element={<RegistroCurso />} />
      <Route path="/Cursos/Editar" element={<RegistroCurso />} />
      <Route path="/EditAviso" element={<EditAviso />} />
      <Route path="/Aceitar" element={<AceitarAluno />} />
      <Route path="/Inscricao" element={<AceitarInscricao />} />
      <Route path="/ListarAlunos" element={<ListarAlunos />} />
    </Routes>
  )
}