export function name(name){
  if(typeof(name) !== 'string'){
    return name = 'Formato inválido!'
  }
  if(name === ''){
    return 'Nome é obrigatório'
  }
}

export function phone(phone){
  if(typeof(phone) !== 'string'){
    return 'Formato inválido!'
  }
  if(phone === ''){
    return 'Telefone é obrigatório'
  }
  if(phone.length !== 15){
    return 'Telefone inválido'
  }
  if(phone.includes('_')){
    return 'Telefone inválido'
  }
}

export function CPF(cpf){
  if(typeof(cpf) !== 'string'){
    return 'Formato inválido!'
  }
  if(cpf === ''){
    return 'CPF é obrigatório'
  }
  if(cpf.length !== 14){
    return 'CPF inválido'
  }
  if(cpf.includes('_')){
    return 'CPF inválido'
  }
}

export function email(email){
  if(typeof(email) !== 'string'){
    return 'Formato inválido!'
  }
  if(email === ''){
    return 'Email é obrigatório'
  }
  if(!email.includes('@')){
    return 'Email não é valido'
  }

}

export function date(date){
  if(typeof(date) !== 'string'){
    return 'Formato inválido!'
  }
  if(date === ''){
    return 'Data obrigatória'
  }
  if(date.length !== 10){
    return 'Data inválida'
  }
}

export function password(password){
  if(typeof(password) !== 'string'){
    return 'Formato inválido!'
  }

  if(password === ''){
    return 'Senha é obrigatória'
  }
  
}

export function confirmPassword(password){
  if(typeof(password) !== 'string'){
    return 'Formato inválido!'
  }

  if(password === ''){
    return 'Confirmar Senha é obrigatória'
  }
  
}

export function selectCourse(teacher, course){

  if(teacher === ''){
    return 'Necessário selecionar professor/curso'
  }
  if(typeof(teacher) !== 'number' || teacher === 0){
    return 'Professor inválido'
  }
  if(course === ''){
    return 'Necessário selecionar professor/curso'
  }
  if(typeof(course) !== 'number' || course === 0){
    return 'Curso inválido'
  }
}

export function time(time){
  let checkTime = time.split(':')
  let hora = parseInt(checkTime[0])
  let min = parseInt(checkTime[1])

  if(time === ''){
    return 'Insira um horário'
  }
  if(time.includes('_') || time.length !== 5){
    return "Horário inválido"
  }
  if(hora < 0 || hora > 23){
    return 'Horário invalido'
  }
  if(min < 0 || min > 59){
    return 'Horário invalido'
  }
  

}

export function id(id){
  if(typeof(id) !== 'number' || id === 0){
    return 'Necessário selecionar um item'
  }  
}
