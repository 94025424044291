import React, { useState } from 'react';

import Header from '../../components/Header';
import Loading from '../../components/Loading'

import './ForgotPassword.scss'

import logo from '../../assets/logo-black.png'
import { useNavigate } from 'react-router-dom'
import InputMask from "react-input-mask";
import { Formik } from 'formik';
import Button from '@mui/material/Button';
import * as Yup from 'yup';

import api from '../../service/api'
import ErrorMessage from '../../components/ErrorMessage';
import SnackAlert from '../../components/Alertas/SnackAlert';


export default function ForgotPassword() {
  const navigate = useNavigate()

  const [ loading, setLoading ] = useState(false)

  const [ enviado, setEnviado ] = useState(false)

  const [ openSnack, setOpenSnack ] = useState(false)
  const [ msgErr, setMsgErr ] = useState('')

  const initialValues = {
    document: '',
  }

  const validationSchema = Yup.object({
    document: Yup.string().required("Campo CPF é obrigatório").matches(/\d{3}.\d{3}.\d{3}-\d{2}/,"CPF está incompleto"),
  })

  async function handleEsqueceuSenha(values){
    setLoading(true)

    try{
      let payload = { document: values.document }

      let res = await api.post('/forgot-password', payload)

      if(res.status === 200){
        setEnviado(true)
      }
    }catch(err){
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
      console.log('err', err.message)
      console.log('err', err.response)
    } finally{
      setLoading(false)
    }
  }

  return (
    <div id='forgot-container'>
      { loading&&
        <Loading />
      }
      <Header title={'ESQUECEU SUA SENHA'} goBack={true} />
      <div className='imgContainer'>
        <img src={logo} alt='logo acordeon'/>
      </div>
      { !enviado ?
        
        <div className='formDiv'>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={values => handleEsqueceuSenha(values) }
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <div className='forgotText'>
                  Insira o CPF da sua conta abaixo.
                </div>
                <div className='forgotText'>
                  Enviaremos um e-mail com um link para redefinir sua senha.
                </div>

                <InputMask
                  id='document'
                  name='document'
                  mask="999.999.999-99"
                  placeholder='CPF'
                  value={props.values.document}
                  onChange={props.handleChange}
                />
                {props.errors.document&& props.touched.document&& <ErrorMessage error={props.errors.document} />}


                <Button className='general-button' variant="outlined" type='submit'>Enviar</Button>
              </form>
            )}
          </Formik>
        </div>
        :  
        <div className='formDiv'>
          <form>
            <div className='forgotText'>
              Um link foi enviado e-mail cadastrado na sua conta. Utilize para redefinir sua senha
            </div>

            <Button className='general-button' variant="outlined" onClick={() => navigate('/')}>Login</Button>

          </form>
        </div>
      }

      <SnackAlert open={openSnack} setOpen={setOpenSnack} type={'error'} msg={msgErr} />
    </div>
  )
}
