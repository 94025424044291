import React, { useState } from 'react'
import Modal from '@mui/material/Modal'

import * as validations from '../../../utils/validations';

import './DeletarHorario.scss'
import formatarHorario from '../../../utils/formatarHorario';

export default function DeletarHorario({modal, modalClose, hour, deletar}) {
  
  return (
    <Modal
      open={modal}
      onClose={modalClose}
    >    
      <div id='deletarhorario-container'>
        <div className='box'>
          Deletar horário?

          { hour&&
            <div className='horario'>{ formatarHorario(hour.from_hour) }</div>
          }

          <div className='btnContainer'>
            <button className='cancelar' onClick={() => modalClose(false)}>Cancelar</button>
            <button className='confirmar' onClick={() => deletar(hour.id)}>Confirmar</button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
