import React, { useState } from 'react'
import formatarHorario from '../../utils/formatarHorario'
import diaSemana from '../../utils/diaSemana'

import { FiEdit } from 'react-icons/fi';

import './CursoAlunoItem.scss'

export default function CursoAlunoItem({aluno, curso, editar}) {

  const [ dropDown, setDropDown ] = useState(false)

  return (
    <div className={`cursoAlunoCard ${ dropDown&& 'cursoAlunoCardOpen' }`}>
      <div onClick={() => setDropDown(!dropDown)} className='header'>
        <div className='headerInfo'>
          <div className='headerValue'>{curso.course.name}</div>
          <div className='headerValue'>{curso.course.qty_class} Aula(s)</div>
        </div>
      </div>
      <div className={dropDown? 'body bodyOpen' : 'body'}>
        <div className='data'>
          <div className='content'>
            <div className='dataInfo'>
              <div className='dataValue'>{diaSemana(curso.hour.teacher_working_hour.week_day)}</div>
              <div className='dataValue'>{formatarHorario(curso.hour.from_hour)}</div>
            </div>
            <div className='dataInfo'>
              <div className='dataValue'>Descrição: </div>
            </div>
          </div>
          <div className='btn-view'>
            <button className='cursoBtn' onClick={() => editar(aluno, curso)}>
              <FiEdit className="icon" size={18} color='#201715'/>
            </button>
          </div>
        </div>
        <div className='desc'>
          <div className='dataValue'>{curso.course.desc}</div>
        </div>
      </div>
    </div>
  )
}
