import React, { useState, useEffect } from 'react'
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@mui/material/Button';

import './RegistroCurso.scss'

import Loading from '../../components/Loading'
import Header from '../../components/Header'
import ErrorMessage from '../../components/ErrorMessage'
import SnackAlert from '../../components/Alertas/SnackAlert';

import logo from '../../assets/logo-black.png'
import { useNavigate } from 'react-router-dom'

import api from '../../service/api'

export default function RegistroCurso() {

  const navigate = useNavigate();
  
  const [ cursoId, setCursoId ] = useState('')
  const [ user_id, setUser_id ] = useState('')
  const [ name, setName ] = useState('')
  const [ qty_class, setQty_class ] = useState('')
  const [ desc, setDesc ] = useState('')

  const count = 240
  
  const [ editar, setEditar ] = useState(false)
  const [ loading, setLoading ] = useState(false)

  const [ openSnack, setOpenSnack ] = useState(false)
  const [ msgErr, setMsgErr ] = useState('')

  const initialValues = {
    name: name,
    qty_class: qty_class,
    desc: desc,
  }

  const validationSchema = Yup.object({
    name: Yup.string().required("Nome do Curso é obrigatório"),
    qty_class: Yup.number().required("Quantidade é obrigatório").min(1, 'Curso deve ter pelo menos 1 aula'),
    desc: Yup.string().required("Descrição é obrigatório").max(240, "Descrição tem um limite de 240 caracteres"),
  })

  async function handleCadastro(values){
    setLoading(true)
    try {
      const payload = {
        user_id,
        name: values.name,
        qty_class: values.qty_class,
        desc: values.desc,
      }

      let res = await api.post('/course', payload)
      if(res.status === 201){
        
        navigate('/Cursos')
      }
    } catch (err) {
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 404){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    } finally {
      setLoading(false)
    }
  }

  async function handleAtualizar(values){
    setLoading(true)
    try{
      const payload = {
        name: values.name,
        qty_class: values.qty_class,
        desc: values.desc,
      }

      let res = await api.put('/course', payload, {
        headers: {
          id: cursoId
        }
      })

      if(res.status === 200){
        sessionStorage.removeItem('editCurso')
        navigate('/Cursos')
      }
    } catch(err) {
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 404){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    } finally {
      setLoading(false)
    }
  }

  function getTeacherId(){
    let id = sessionStorage.getItem('teacherId')

    if(!id){
      id = 1
    }

    setUser_id(id)
  }
  
  async function getCurso(){
    let curso = JSON.parse(sessionStorage.getItem('editCurso'))
    console.log('curso', curso)
    if(curso !== null){
      setEditar(true)

      setCursoId(curso.id)
      setName(curso.name)
      setQty_class(curso.qty_class)
      setDesc(curso.desc? curso.desc : '')
    }    
  }

  useEffect(() => { 

    getCurso()

    getTeacherId()
  },[])

  return (
    <div id='registrocurso-container'>
      { loading&&
        <Loading />
      }
      <Header title={editar?'EDITAR CURSOS':'NOVO CURSO'} goBack={true} />
      
      <div className='imgContainer'>
        <img src={logo} alt='logo acordeon'/>
      </div>
      <div className='formDiv'>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={values => editar?handleAtualizar(values):handleCadastro(values) }
          enableReinitialize={true}
        >
          {(props) => (
          <form onSubmit={props.handleSubmit}>

            <input
              id='name'
              name='name'
              placeholder='Nome do Curso' 
              value={props.values.name}
              onChange={props.handleChange}
            />
            {props.errors.name&& props.touched.name&& <ErrorMessage error={props.errors.name} />}
            <input
              id='qty_class'
              name='qty_class'
              type='number'
              placeholder='Duração (Quantidade de Aulas)'
              value={props.values.qty_class}
              onChange={props.handleChange}
            />
            {props.errors.qty_class&& props.touched.qty_class&& <ErrorMessage error={props.errors.qty_class} />}
            <textarea
              id='desc'
              name='desc'
              placeholder='Digite uma descrição para o curso...'
              rows={6}
              maxLength={count}
              value={props.values.desc}
              onChange={props.handleChange}
            />
            <div className='textarea-count'>{`${props.values.desc.length} / ${count}`}</div>
            {props.errors.desc&& props.touched.desc&& <ErrorMessage error={props.errors.desc} />}
            
            <Button className='general-button' variant="outlined" type='submit'>{editar?'Atualizar':'Cadastrar'}</Button>
          </form>
        )}
        </Formik>
      </div>
      <SnackAlert open={openSnack} setOpen={setOpenSnack} type={'error'} msg={msgErr} />
    </div>
  )
}
