import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import {  FiLogOut } from 'react-icons/fi';
import api from '../../service/api';
import './Menu.scss'

import logo from '../../assets/logo.png'

export default function Menu({open, closeMenu, adm, openAviso}) {

  const navigate = useNavigate()

  const [ user, setUser ] = useState('')
  
  const [ newStudents, setNewStudents ] = useState(false)
  const [ newAssigns, setNewAssigns ] = useState(false)

  function editProfile(){
    navigate('/Perfil')
  }

  async function getUserData(){
    let user = JSON.parse(sessionStorage.getItem('user'))

    let name = user.name.split(' ')
    setUser(`${name[0]} ${name[1]}`)
  }

  function logout(){
    sessionStorage.clear()

    navigate('/')
  }

  async function checkNewStudent(){
    try{
      let res = await api.get('/user?auth=false')

      if(res.status === 200){
        setNewStudents(res.data.users.length !== 0? true : false)
      }
    } catch(err){
      console.log('err :: ', err.message)
      console.log('err :: ', err.response)
    }
  }

  async function checkNewAssigns(){
    try{
      let res = await api.get('/new-links')

      if(res.status === 200){
        setNewAssigns(res.data.users.length !== 0? true : false)
      }
    } catch(err){
      console.log('err :: ', err.message)
      console.log('err :: ', err.response)
    }
  }

  useEffect(()=>{ 
    getUserData()
    checkNewStudent()
    checkNewAssigns()
  },[open])

  return (
    <>
      <div onClick={() => closeMenu(!open)} className={`drawerComp ${open && "drawerCompOpen"}`}/>
      <div className={`menu ${open && "openMenu"}`}>
        <div className='header'>
          <h3>MENU</h3>
        </div>
        <img src={logo} alt='logo acordeon'/>
        <div className='userInfo'>
          <button onClick={() => editProfile()} className='btnProfile'>
            { adm?
              <div className='title'>PROFESSOR</div>
              :
              <div className='title'>ALUNO</div>
            }
            <div className='userName'>{user}</div>
          </button>
        </div>
        <div className='listaMenu'>
          { adm ?
            (
              <> 
                <Link className='menuItem' to='/HomeAdm'>Home</Link>
                <Link className='menuItem' to='/EditAviso'>Editar Avisos</Link> 
                <Link className='menuItem' to='/DefinirHorarios'>Expediente</Link>
                <Link className='menuItem' to='/HorariosAdm'>Gerenciar Horários</Link>
                <Link className='menuItem' to='/FeriasAdm'>Gerenciar Férias</Link>
                <Link className='menuItem' to='/ListarAlunos'>Lista de Alunos</Link>
                <Link className='menuItem' to='/Cursos'>Cursos</Link>
                <Link className='menuItem' to='/Cadastro/Dados'>Cadastrar Aluno</Link>
                {/* <div className='menuItem' onClick={() => navigate('/Aceitar')}>Novos Alunos{ newStudents&& <div className='indicator'/>}</div> */}
                <div className='menuItem' onClick={() => navigate('/Inscricao')}>Novas Inscrições{ newAssigns&& <div className='indicator'/>}</div>
              </>
            ):(
              <>
                {/* <Link className='menuItem' to='/HomeUser'>Reagendamento</Link> */}
                <div className='menuItem' onClick={() => openAviso(true)}>Avisos</div> 
                {/* <Link className='menuItem' to='/MeusCursos'>Alterar Horário</Link> */}
                <Link className='menuItem' to='/NovoCurso'>Novo Curso</Link>
              </>
            )
          }
        </div>
        <div className='sair' onClick={() => logout()}>
          <FiLogOut className='icon' size={20} color='#faffff'/>
          sair
        </div>
      </div>
    </>
  )
}
