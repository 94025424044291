import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import AlunoItem from '../../components/AlunoItem';
import Loading from '../../components/Loading'
import Header from '../../components/Header'
import Menu from '../../components/Menu';
import ConfirmCursos from '../../components/Modals/ConfirmCursos'
import ConfirmAlunos from '../../components/Modals/ConfirmAlunos'
import SnackAlert from '../../components/Alertas/SnackAlert';
import './ListarAlunos.scss'

import api from '../../service/api'

export default function ListarAlunos() {

  const navigate = useNavigate();

  const [ alunos, setAlunos ] = useState([])
  
  const [ loading, setLoading ] = useState(false)
  const [ menu, openMenu ] = useState(false)
  const [ selectedCourse, setSelectedCourse ] = useState('')
  const [ selectedStudent, setSelectedStudent ] = useState('')
  
  const [ modalCurso, setModalCurso ] = useState(false)
  const [ modalAluno, setModalAluno ] = useState(false)

  const [ openSnack, setOpenSnack ] = useState(false)
  const [ msgErr, setMsgErr ] = useState('')
  const [ snackType, setSnackType ] = useState('')

  async function getAlunos(){
    setLoading(true)
    try{
      let res = await api.get('/user?auth=true')

      if(res.status === 200){
        setAlunos(res.data.users)
      }
    } catch(err){
      setSnackType('error')
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    } finally {
      setLoading(false)
    }
  }
  
  async function deleteCourse(user_id, user_course_id){

    try{
      let payload = { 
        user_id,
        user_course_id,
        disable_user: false
      }

      console.log('pay', payload)

      let res = await api.put('/disable-user', payload)

      if(res.status === 200){
        setModalCurso(false)
        setSelectedCourse('')
        setSnackType('success')
        setMsgErr(res.data.message)
        setOpenSnack(true)
        getAlunos()
      }
    }catch(err){
      setSnackType('error')
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    }
  }
  
  async function deleteStudent(user_id){

    try{
      let payload = { 
        user_id,
        disable_user: true
      }

      console.log('pay', payload)

      let res = await api.put('/disable-user', payload)

      if(res.status === 200){
        setModalAluno(false)
        setSelectedStudent('')
        setSnackType('success')
        setMsgErr(res.data.message)
        setOpenSnack(true)
        getAlunos()
      }
    }catch(err){
      setSnackType('error')
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    }
  }

  function editCurso(aluno, curso){
    let payload = {
      aluno,
      curso,
    }

    sessionStorage.setItem('editarHorario', JSON.stringify(payload))

    navigate('/AlterarHorario')
  }

  async function getReport(id, name, course){
    try{
      let res = await api.get(`/report/${name}`, {
          responseType: 'arraybuffer',
          headers: {
            id: id
          }
      })
      
      if(res.status === 200){
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name} - ${course}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    }catch(err){
      setSnackType('error')
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    }
  }

  useEffect(() => {
    getAlunos()
  }, [])  

  return (
    <div id='listaalunos-container'>
      { loading&&
        <Loading />
      }
      <Header title={'LISTA DE ALUNOS'} menu={menu} openMenu={openMenu} />
      <ConfirmCursos modal={modalCurso} modalClose={setModalCurso} course={selectedCourse} clean={setSelectedCourse} confirm={deleteCourse}/>
      <ConfirmAlunos modal={modalAluno} modalClose={setModalAluno} aluno={selectedStudent} clean={setSelectedStudent} confirm={deleteStudent}/>
      
      {alunos.length === 0&&
        <div className='emptyList'>Não há nenhum aluno ativo no momento...</div>
      }
      { alunos.map((aluno) => (
        <AlunoItem key={aluno.id} aluno={aluno} openConfirmAluno={setModalAluno} openConfirmCurso={setModalCurso} deletarAluno={setSelectedStudent} deletarCurso={setSelectedCourse} editar={editCurso} report={getReport}/>
      ))}
      <Menu open={menu} closeMenu={openMenu} adm={true}/>
      <SnackAlert open={openSnack} setOpen={setOpenSnack} type={snackType} msg={msgErr} />
    </div>      
  )
}
