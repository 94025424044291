import React, { useState } from 'react'

import { FiEdit, FiTrash2, FiFileText } from 'react-icons/fi';

import formatarHorario from '../../utils/formatarHorario'
import diaSemana from '../../utils/diaSemana'

import './AlunoItem.scss'

export default function AlunoItem({aluno, openConfirmCurso, openConfirmAluno, deletarAluno, deletarCurso, editar, report}) {

  const [ dropDown, setDropDown ] = useState(false)
  
  return (
    <div className={`alunoItem ${ dropDown&& 'alunoItemOpen' }`}>
      <div onClick={() => setDropDown(!dropDown)} className='header'>
        <div className='headerValue'>{aluno.name}</div>
      </div>
      <div className={dropDown? 'body bodyOpen' : 'body'}>
        <div className='data'>
          <div className='aluno-view'>
            <div className='email'>{aluno.email}</div>
            <div className='phone'>{aluno.phone_number}</div>
          </div>
          <button className='cursoBtn' onClick={() => {
            deletarAluno({user_id: aluno.id, user: aluno.name})
            openConfirmAluno(true)
          }}>
            <FiTrash2 className="icon" size={15} color='#ff0000'/>
          </button>
        </div>
        <div className='course-container'>
          { aluno.user_courses.map(c => (
            <div key={c.id} className='course-view'>
              <div className='course-info'>
                <div className='course-name'>{c.course.name}</div>
                <div className='course-day'>{diaSemana(c.hour.teacher_working_hour.week_day)}</div>
                <div className='course-qty'>{c.course.qty_class} {'Aula(s)'}</div>
                <div className='course-hour'>{formatarHorario(c.hour.from_hour)}</div>
              </div>
              <div className='btn-view'>
                <button className='cursoBtn' onClick={() => editar(aluno.name, c)}>
                  <FiEdit className="icon" size={15} color='#201715'/>
                </button>
                <button className='cursoBtn' onClick={() => report(c.id, aluno.name, c.course.name)}>
                  <FiFileText className="icon" size={15} color='#201715'/>
                </button>
                <button className='cursoBtn' onClick={() => {
                    deletarCurso({user_course_id: c.id, course: c.course.name, user_id: aluno.id, user: aluno.name})
                    openConfirmCurso(true)
                  }}
                >
                  <FiTrash2 className="icon" size={15} color='#ff0000'/>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}