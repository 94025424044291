import React, { useState } from 'react'
import { FiEye, FiEyeOff } from 'react-icons/fi';

import './PasswordInput.scss'

export default function PasswordInput(input) {

  const [ showPassword, setShowPassword ] = useState('password')

  return (
    <div id='inputContainer'>
      <input
        className='passwordInput'
        id={input.id}
        name={input.name}
        placeholder={input.holder}
        type={showPassword} 
        value={input.value}
        onChange={input.onChange}
      />
      <div className='iconContainer' >
        { showPassword === 'password' ?
          <FiEyeOff onClick={() => setShowPassword('text')} size={21} color={input.white?'#FAFFFF':'#262b44'}/>
          :
          <FiEye onClick={() => setShowPassword('password')} size={21} color={input.white?'#FAFFFF':'#262b44'}/>
        }
      </div>
    </div>
  )
}
