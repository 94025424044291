import React, { useState } from 'react'

import { FiEdit } from 'react-icons/fi';

import './CursoItem.scss'

export default function CursoItem({curso, edit, gerenciarCurso}) {

  const [ arq, setArq ] = useState(curso.is_active)

  return (
    <div className={`cursoItem ${arq===true&& 'cursoItemActive'}`}>
      <div className={`header ${arq===true&& 'headerActive'}`}>
        <div className='headerValue'>{arq === true ? 'Ativo':'Inativo'}</div>
        <div className='headerInfo'>
          <div className='headerCourse'>{curso.name}</div>
          <div className='headerBtn'>
            <label className="switch">
              <input type="checkbox" checked={arq} onChange={() => {
                  gerenciarCurso(curso.id, !arq)
                  setArq(!arq)
                }}
              />
              <span className="slider round"/>
            </label>
          </div>
        </div>
      </div>
      <div className='infoView'>
        <div className='dataView'>
          <div className='data'>
            <div className='dataLabel'>Qtd. Aulas:</div>
            <div className='dataValue'>{curso.qty_class}</div>
          </div>
          <div className='descLabel'>Descrição:</div>
        </div>
        { arq === true&&
          <div className='btnContainer'>
            <button className='cursoBtn' onClick={() => edit(curso)}>
              <FiEdit className="icon" size={15} color='#262b44'/>
            </button>
          </div>
        }
      </div>

      <div className='desc'>
        <div className='dataValue'>{curso.desc}</div>
      </div>

      {/* <div className={deletar ? 'deleteConfirm deleteConfirmOpen' : 'deleteConfirm'}>
        Arquivar curso?
        <button onClick={() => deleteCurso(curso.id)}>SIM</button>
        <button onClick={() => setDeletar(false)}>NÃO</button>
      </div> */}
    </div>
  )
}
