import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FiInfo } from 'react-icons/fi';
import InputMask from "react-input-mask";
import Button from '@mui/material/Button';

import './CadastroDados.scss'

import Header from '../../components/Header'
import Loading from '../../components/Loading'
import ErrorMessage from '../../components/ErrorMessage';
import ErrorBox from '../../components/ErrorBox';
import PasswordInput from '../../components/PasswordInput';

import api from '../../service/api'

import * as validations from '../../utils/validations';
import formatarHorario from '../../utils/formatarHorario';

import logo from '../../assets/logo-black.png'
import DetalhesCurso from '../../components/Modals/DetalhesCurso';
import SnackAlert from '../../components/Alertas/SnackAlert';
import DateSelector from '../../components/DateSelector';

export default function CadastroDados() {

  const navigate = useNavigate();

  const [ name, setName ] = useState('')
  const [ phone_number, setPhone_number ] = useState('')
  const [ document, setDocument ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ birthday, setBirthday ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ confirmPassword, setConfirmPassword ] = useState('')
  const [ day, setDay ] = useState('')
  const [ teacher_id, setTeacher_id ] = useState('')
  const [ course_id, setCourse_id ] = useState('')
  const [ hour_id, setHour_id ] = useState('')
  const [ message_notification_app, setMessage_notification_app ] = useState(false)
  const [ message_notification_email, setMessage_notification_email ] = useState(true)

  const [ errors, setErrors ] = useState({})
  
  const [ curso, setCurso ] = useState('')
  const [ dateType, setDateType ] = useState('text')
  const [ errSenha, setErrSenha ] = useState(false)
  const [ detalhesCurso, setDetalhesCurso ] = useState(false)
  const [ firstPart, setFirstPart ] = useState(true)
  const [ loading, setLoading ] = useState(false)
  
  const [ cursosProfessores, setCursosProfessores ] = useState([])
  const [ hours, setHours ] = useState([])

  const [ openSnack, setOpenSnack ] = useState(false)
  const [ msgErr, setMsgErr ] = useState('')

  async function handleCadastro(){
    
    let err = {}
    if(validations.selectCourse(teacher_id, course_id)){
      err.selectCourse = validations.selectCourse(teacher_id, course_id)
    }
    if(validations.id(day) && course_id !== ''){
      err.weekday = 'Necessário selecionar um dia da semana'
    }
    if(validations.id(hour_id) && day !== ''){
      err.hour = 'Necessário selecionar um horário'
    }
    setErrors(err)

    if(JSON.stringify(err) === JSON.stringify({})){

      setLoading(true)
    
      try{
        let payload = {
          name,
          phone_number,
          document,
          email,
          birthday: birthday.toLocaleDateString('pt-BR').split('/').reverse().join('-'),
          password: birthday.toLocaleDateString('pt-BR').split('/').reverse().join(''),
          message_notification_app,
          message_notification_email,
          lookup_role_id: 2,
          teacher_id,
          course_id,
          hour_id,
          day,
        }
    
        console.log('data', payload)

        let res = await api.post('/user', payload)

        if(res.status === 200){
          console.log(res)

          navigate('/')
        }

      } catch(err) {
        console.log('err', err.response)
        console.log('err', err.message)
      } finally{
        setLoading(false)
      }
    }

  }

  function checkCadastro(){
    setErrSenha(false)
    console.log('date', birthday.toLocaleDateString('pt-BR').split('/').reverse().join("-"))

    let err = {}
    if(validations.name(name)){
      err.name = validations.name(name)
    }
    if(validations.phone(phone_number)){
      err.phone = validations.phone(phone_number)
    }
    if(validations.CPF(document)){
      err.cpf = validations.CPF(document)
    }
    if(validations.email(email)){
      err.email = validations.email(email)
    }
    if(birthday !== ''){  
      if(validations.date(birthday.toLocaleDateString('pt-BR'))){
        err.fim = validations.date(birthday.toLocaleDateString('pt-BR'))
      }
    } else {
      err.fim = 'Data é obrigatória'
    }
    /* if(validations.password(password)){
      err.password = validations.password(password)
    }
    if(validations.confirmPassword(confirmPassword)){
      err.confirmPassword = validations.confirmPassword(confirmPassword)
    } */
    setErrors(err)

    if(JSON.stringify(err) === JSON.stringify({})){
      if(password === confirmPassword){
        setFirstPart(false)
        getProfessores()
      } else {
        setErrSenha(true)
      }
    }
    
  }

  async function getProfessores(){
    setLoading(true)
    try {
      let res = await api.get('/teacher')

      if(res.status === 200){
        setCursosProfessores(res.data.teachers)
      }
    } catch (err){
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    }finally{
      setLoading(false)
    }
  }

  async function getHours(teacher_id){

    setLoading(true)
    try {
      let res = await api.get('/workingHours', {
        headers: {
          id: teacher_id
        }
      })

      if(res.status === 200){
        setHours(res.data)
      }
    } catch (err) {
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    } finally{
      setLoading(false)
    }
  }

  function manageTeacherCourse(values){
    setDay('')
    let ids = values.split('|')
    let course = JSON.parse(ids[1])

    setCurso(course.course)
    setTeacher_id(parseInt(ids[0]))
    setCourse_id(course.course_id)
    getHours(parseInt(ids[0]))
  }

  function maskName(name){
    var loweredText = name.value.toLowerCase().trim()

    var word = loweredText.split(" ")
    
    for(var a= 0; a < word.length; a++){
      var w = word[a]

      var firstLetter = w[0];

      w = firstLetter.toUpperCase() + w.slice(1);

      word[a] = w;
    }

    setName(word.join(" "))
  }

  const maskBirthday = (birth, edit) => {
    if(!edit){
      let value = birth.split('-').reverse().join("/")

      setBirthday(value)

      setDateType('text')
    } else {
      let value = birth.split('/').reverse().join("-")

      setBirthday(value)

      setDateType('date')
    }
  }

  return (
    <div id='cadastro-container'>
      { loading&&
        <Loading />
      }
      <DetalhesCurso modal={detalhesCurso} modalClose={setDetalhesCurso} curso={curso} />
      <Header title={'CADASTRO'} goBack={true}/>
    
      <div className='imgContainer'>
        <img src={logo} alt='logo acordeon'/>
      </div>
      { firstPart?
        <div className='formDiv'>
          <div className='formBody'>

            <input id='name' placeholder='Nome' onBlur={v => maskName(v.target)} value={name} onChange={v => setName(v.target.value)}/>
            {errors.name&& <ErrorMessage error={errors.name} />}
            <InputMask id='phone_number' mask={phone_number.length<14?"(99) 9999-9999":"(99) 99999-9999"} maskPlaceholder='' placeholder='Telefone' onChange={v => setPhone_number(v.target.value)}/>
            {errors.phone&& <ErrorMessage error={errors.phone} />}
            <InputMask id='document' mask="999.999.999-99" placeholder='CPF' onChange={v => setDocument(v.target.value)}/>
            {errors.cpf&& <ErrorMessage error={errors.cpf} />}
            <input id='email' placeholder='E-mail' type='email' onChange={v => setEmail(v.target.value)} />
            {errors.email&& <ErrorMessage error={errors.email} />}
            <DateSelector date={birthday} setDate={setBirthday} place={'Data de Nascimento'}/>
            {errors.date&& <ErrorMessage error={errors.date} />}
            {/* <PasswordInput id={'password'} name={'password'} holder={'Senha'} value={password} onChange={v => setPassword(v.target.value)} />
            {errors.password&& <ErrorMessage error={errors.password} />}
            <PasswordInput id={'confirmPassword'} name={'confirmPassword'} holder={'Confirmar Senha'} value={confirmPassword} onChange={v => setConfirmPassword(v.target.value)} />
            {errors.confirmPassword&& <ErrorMessage error={errors.confirmPassword} />} */}

            {errSenha&& <ErrorBox err={'As senhas não correspondem'} close={setErrSenha}/>}

            <Button className='general-button' variant="outlined" onClick={() => checkCadastro()}>Próximo</Button>
          </div>
        </div>
        :
        <div className='formDiv'>
          <div className='formBody'>
            <select onChange={v => manageTeacherCourse(v.target.value)}>
              <option value="" hidden defaultValue>Curso</option>
              { cursosProfessores.map(prf => prf.user_courses.map( c => (
                <option key={c.id} value={`${prf.id}|${JSON.stringify(c)}`}>{`${prf.name} - ${c.course.name}`}</option>
              )))}
            </select>
            {errors.selectCourse&& <ErrorMessage error={errors.selectCourse} />}

            { curso&&
              <div onClick={() => setDetalhesCurso(true)} className='courseDetails'>
                <FiInfo className='icon' size={16} color={'#262b44'}/>
                Informações do curso
              </div>
            }

            <div className='weekday'>
              { course_id !== ''&&
                <>
                  <div onClick={() => setDay(1)} className={`dayItem ${ day === 1&& 'dayItem-selected'}`}>SEG</div>
                  <div onClick={() => setDay(2)} className={`dayItem ${ day === 2&& 'dayItem-selected'}`}>TER</div>
                  <div onClick={() => setDay(3)} className={`dayItem ${ day === 3&& 'dayItem-selected'}`}>QUA</div>
                  <div onClick={() => setDay(4)} className={`dayItem ${ day === 4&& 'dayItem-selected'}`}>QUI</div>
                  <div onClick={() => setDay(5)} className={`dayItem ${ day === 5&& 'dayItem-selected'}`}>SEX</div>
                  <div onClick={() => setDay(6)} className={`dayItem ${ day === 6&& 'dayItem-selected'}`}>SAB</div>
                </>
              }
            </div>
            {errors.weekday&&course_id !== ''&& <ErrorMessage error={errors.weekday} />}
            
            <div className='time'>
              { hours.map(h => h.week_day === day&&(
                <>
                  { h.hours.map(hour => (
                    <>
                      { hour.is_available&&
                        <div key={hour.id} onClick={() => setHour_id(hour.id)} className={`timeStamp ${ hour_id === hour.id&& 'timeStamp-selected'}`}>{formatarHorario(hour.from_hour)}</div>
                      }
                    </>
                  ))}
                </>
              ))}
            </div>
            {errors.hour&&day!==''&& <ErrorMessage error={errors.hour} />}

            <div className='email'>
              <input className='check' type='checkbox' onChange={() => setMessage_notification_email(!message_notification_email)}/>
              Não receber e-mails de avisos de aulas
            </div>

            <Button className='general-button' variant="outlined" onClick={() => handleCadastro()}>Cadastrar</Button>

          </div>
        </div> 
      }
      <div className='bottom'>
        Já possui conta? <Link className='link' to='/'>Faça Login</Link>
      </div>

      <SnackAlert open={openSnack} setOpen={setOpenSnack} type={'error'} msg={msgErr} />
      
    </div>
          
  )
}
