import React, { useState, useEffect } from 'react'
import InputMask from "react-input-mask";
import Modal from '@mui/material/Modal'

import * as validations from '../../../utils/validations';

import ErrorMessage from '../../ErrorMessage';
import './EditarHorario.scss'

export default function CriarHorario({modal, modalClose, hour, edit}) {

  const [ horario, setHorario ] = useState('')
  const [ err, setErr ] = useState('')

  function validate(){
    if(validations.time(horario)){
      setErr(validations.time(horario))
    } else {
      let time = horario.replace(':', '.')
      edit(parseInt(parseFloat(time) * 60), hour.id)
    }
  }

  function setHour(){
    if(hour !== ''){
      let time = (hour.from_hour / 60).toFixed(2)

      let formated = time.toString().split('.')
      let h = formated[0]
      let m = formated[1]

      if(h.length === 1){
        h = `0${h}`
      }
      if(m < 10){
        m = `0${m}`
      }
      if(m.toString().includes('.')){
        m = parseInt(m)
      }
      console.log(h + m)

      setHorario(h + m)
    }
  }

  useEffect(() => {
    setHour()
  }, [hour])

  return (
    <Modal
      open={modal}
      onClose={modalClose}
    >    
      <div id='editarhorario-container'>
        <div className='box'>
          Alterar horário

          <div className='novo-horario'>
            Insira o novo horário:
            <InputMask mask="99:99" placeholder='00:00' value={horario} onChange={v => setHorario(v.target.value)}/>
          </div>
          {err!==''&& <ErrorMessage error={err} />}

          <div className='btnContainer'>
            <button className='cancelar' onClick={() => modalClose(false)}>
              Cancelar
            </button>
            <button className='confirmar' onClick={() => validate()}>Confirmar</button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
