import React from 'react'
import Modal from '@mui/material/Modal'

import './ConfirmAlunos.scss'

export default function ConfirmAlunos({modal, modalClose, aluno, clean, confirm}) {

  return (
    <Modal
      open={modal}
      onClose={modalClose}
    >    
      <div id='curso-container'>
        <div className='box'>
          <div className='details-curso'>
            {aluno.user}
          </div>
          Deseja deletar este aluno?

          <div className='btnContainer'>
            <button className='cancelar' onClick={() => {
                modalClose(false)
                clean('')
              }} 
            >
              Cancelar
            </button>
            <button className='confirmar' onClick={() => confirm(aluno.user_id)}>Confirmar</button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
