import React, { useState } from 'react'
import InputMask from "react-input-mask";
import Modal from '@mui/material/Modal'

import * as validations from '../../../utils/validations';

import ErrorMessage from '../../ErrorMessage';
import './CriarHorario.scss'

export default function CriarHorario({modal, modalClose, novo}) {

  const [ horario, setHorario ] = useState('')
  const [ err, setErr ] = useState('')

  function validate(){
    console.log('horario', horario)

    if(validations.time(horario)){
      setErr(validations.time(horario))
    } else {
      let time = horario.replace(':', '.')
      novo(parseInt(parseFloat(time) * 60))
    }
  }

  return (
    <Modal
      open={modal}
      onClose={modalClose}
    >    
      <div id='criarhorario-container'>
        <div className='box'>
          Criar novo horário

          <div className='novo-horario'>
            Insira o novo horário:
            <InputMask mask="99:99" placeholder='00:00' onChange={v => setHorario(v.target.value)}/>
          </div>
          {err!==''&& <ErrorMessage error={err} />}

          <div className='btnContainer'>
            <button className='cancelar' onClick={() => modalClose(false)}>
              Cancelar
            </button>
            <button className='confirmar' onClick={() => validate()}>Confirmar</button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
