import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button';

import { FiX } from 'react-icons/fi';

import Header from '../../components/Header';
import Menu from '../../components/Menu';
import Loading from '../../components/Loading';
import VacationItem from '../../components/VacationItem';
import CriarFerias from '../../components/Modals/CriarFerias'
import DeletarFerias from '../../components/Modals/DeletarFerias'
import SnackAlert from '../../components/Alertas/SnackAlert';
import api from '../../service/api';

import './FeriasAdm.scss';

export default function FeriasAdm() {

  /* let ferias = [
    {id: 1, dt_init: 'DD/MM/AA', dt_fim: 'DD/MM/AA', user_id: 1},
    {id: 2, dt_init: 'DD/MM/AA', dt_fim: 'DD/MM/AA', user_id: 1},
    {id: 3, dt_init: 'DD/MM/AA', dt_fim: 'DD/MM/AA', user_id: 1},
  ] */
  
  const navigate = useNavigate();
  
  const [ prf_id, setPrf_id ] = useState('')
  const [ vacationList, setVacationList ] = useState([])
  const [ vacation, setVacation ] = useState('')

  const [ createFerias, setCreateFerias ] = useState(false)
  const [ confirmCancelar, setConfirmCancelar ] = useState(false)

  const [ loading, setLoading ] = useState(false)
  const [ menu, openMenu ] = useState(false)

  const [ openSnack, setOpenSnack ] = useState(false)
  const [ msgErr, setMsgErr ] = useState('')
  const [ snackType, setSnackType ] = useState('')

  function getProfessor(){
    let prf = sessionStorage.getItem('teacherId')
    setPrf_id(prf)
  }

  async function getVacation(){
    setLoading(true)
    try {
      let prf = sessionStorage.getItem('teacherId')
      setPrf_id(prf)
      let res = await api.get('/vacation', {
        headers: {
          id: prf
        }
      })

      if(res.status === 200){
        setVacationList(res.data)
      }
    } catch (err) {
      setSnackType('error')
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    } finally {
      setLoading(false)
    }
  }

  async function createVacation(dt_init, dt_fim){
    try{
      let payload = {
        vacation_start: dt_init.toLocaleDateString('pt-BR').split('/').reverse().join("-"),
        vacation_end: dt_fim.toLocaleDateString('pt-BR').split('/').reverse().join("-"),
      }

      let res = await api.post('/vacation', payload, {
        headers: {
          id: prf_id
        }
      })

      if(res.status === 200){
        setCreateFerias(false)
        setSnackType('success')
        setMsgErr(res.data.message)
        setOpenSnack(true)
        getVacation()
      }
    }catch(err){
      setSnackType('error')
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 404){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    }
    
  }

  async function deleteVacation(vac_id){
    try{

      let res = await api.delete('/vacation', {
        headers: {
          id: vac_id
        }
      })

      if(res.status === 200){
        setConfirmCancelar(false)
        setSnackType('success')
        setMsgErr(res.data.message)
        setOpenSnack(true)
        getVacation()
      }
    }catch(err){
      setSnackType('error')
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 404){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    }
    
  }

  function selectVacation(item){
    setConfirmCancelar(true)
    setVacation(item)
  }
  
  useEffect(() => {
    setLoading(true)
    getProfessor()
    getVacation()
  }, [])

  
  return (
    <div id='ferias-container'>
      { loading&&
        <Loading />
      }
      <Header title={'GERENCIAR FÉRIAS'} menu={menu} openMenu={openMenu} />
      <CriarFerias modal={createFerias} modalClose={setCreateFerias} create={createVacation}/>
      <DeletarFerias modal={confirmCancelar} modalClose={setConfirmCancelar} vacation={vacation} deletar={deleteVacation}/>
      
      <Button variant='outlined' className={`general-button save`} onClick={() => setCreateFerias(true)}>Adicionar Ferias</Button>

      <div className='titleContainer'>
        <div className='title'>Lista de ferias programadas</div>
      </div>

      <div className='vacations'>
        { vacationList==0?
          <div>
            Não há férias registradas
          </div>
          :
          <>
            <div className='vacationTitle'>Data</div>
            {vacationList.map(f => (
              <VacationItem item={f} deletar={selectVacation}/>
            ))}
          </>
        }
      </div>

      <Menu open={menu} closeMenu={openMenu} adm={true}/>
      <SnackAlert open={openSnack} setOpen={setOpenSnack} type={snackType} msg={msgErr} />
    </div>
  )
}
