import React, { useState, useEffect } from 'react'
import Modal from '@mui/material/Modal'
import { FiX } from 'react-icons/fi'

import './Aviso.scss';
import api from '../../../service/api';
import SnackAlert from '../../Alertas/SnackAlert';

export default function Aviso({modal, modalClose}) { 
  
  const [ avisos, setAvisos ] = useState([])
  const [ openSnack, setOpenSnack ] = useState(false)
  const [ msgErr, setMsgErr ] = useState('')

  async function getAvisos(){
    try {
      let res = await api.get('/notes')

      if(res.status === 200){
        let formated = res.data.note.notes.split('\n')

        console.log(formated)

        setAvisos(formated)
      }
    } catch (err) {
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    }
  }

  useEffect(() => {
    getAvisos()
  }, [])

  return (
    <Modal
      open={modal}
      onClose={modalClose}
    >
      <div id='aviso-container'>
        <div className='box'>
          <div className='header'>
            Avisos:
            <button className='close' onClick={() => modalClose(false)}><FiX size={25} color='gray' /></button>
          </div>

          <div className='desc'>
            { avisos.map(a => 
              <p className='descText'>{a}</p>
            )}
              
          </div>
        </div>
        <SnackAlert open={openSnack} setOpen={setOpenSnack} type={'error'} msg={msgErr} />
      </div>
    </Modal>
  )
}
