import React from 'react'

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

export default function SnackAlert({open, setOpen, type, msg}) {
  function handleClose(event, reason){
    setOpen(false)
  }
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={open} onClose={handleClose} autoHideDuration={15000}>
      <Alert onClose={handleClose} severity={type} sx={{width: '100%', alignSelf: 'center'}}>
        {msg}
      </Alert>
    </Snackbar>
  )
}
