import React, { useState, useEffect } from 'react'

import Loading from '../../components/Loading'
import Header from '../../components/Header'
import Menu from '../../components/Menu';
import CursoItem from '../../components/CursoItem'
import SnackAlert from '../../components/Alertas/SnackAlert';

import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom'
import api from '../../service/api'
import './ListaCursos.scss'

export default function ListarCursos() {
  const navigate = useNavigate()
  
  const [ listaCursos, setListaCursos ] = useState([])
  
  const [ loading, setLoading ] = useState(false)
  const [ menu, openMenu ] = useState(false)

  const [ openSnack, setOpenSnack ] = useState(false)
  const [ msgErr, setMsgErr ] = useState('')
  const [ typeSnack, setTypeSnack ] = useState('')

  async function editarCurso(curso){
    console.log('alow', curso)
    sessionStorage.setItem('editCurso', JSON.stringify(curso))
    navigate('/Cursos/Cadastro')
  }

  async function getLista(){
    setLoading(true)
    try {
      let res = await api.get('/course')

      console.log('res: ', res)
      if(res.status === 200){
        setListaCursos(res.data)
      }
    } catch (err) {
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    } finally {
      setLoading(false)
    }
  }

  async function gerenciar(id, is_active){
    try{
      console.log('gerenciar')
      let payload = {
        id,
        is_active,
      }

      let res = await api.put('/disable-course', payload)

      if(res.status === 200){
        setTypeSnack('success')
        setMsgErr(res.data.message)
        setOpenSnack(true)
        //getLista()
      }
    }catch(err){
      setTypeSnack('error')
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    }
  }

  useEffect(()=>{
    getLista()
  },[])

  return (
    <div id='listacursos-container'>
      { loading&&
        <Loading />
      }
      <Header title={'CURSOS'} menu={menu} openMenu={openMenu} />
      <Button className='general-button' variant="outlined" 
        onClick={() => {
          sessionStorage.removeItem('editCurso')
          navigate('/Cursos/Cadastro')
        }}
      >
        Cadastrar
      </Button>
      { listaCursos&&
        <>
          { listaCursos.map((curso) => (
            <CursoItem key={curso.id} curso={curso} edit={editarCurso} gerenciarCurso={gerenciar}/>
          ))}
        </> 
      }    
      <Menu open={menu} closeMenu={openMenu} adm={true}/>
      <SnackAlert open={openSnack} setOpen={setOpenSnack} type={typeSnack} msg={msgErr} />
    </div> 
  )
}