import React, { useState } from 'react'
import diaSemana from '../../utils/diaSemana'
import formatarHorario from '../../utils/formatarHorario'

import './NovaInscricaoItem.scss'

export default function NovaInscricaoItem({aluno, insc, auth}) {

  const [ show, setShow ] = useState(true)
  const [ dropDown, setDropDown ] = useState(false)

  return (
    <>
      { show === true&&
        <div className={`novaInscricaoCard ${ dropDown&& 'novaInscricaoCardOpen' }`}>
          <div onClick={() => setDropDown(!dropDown)} className='header'>
            <div className='headerInfo'>
              <div className='headerValue'>{insc.course.name}</div>
              <div className='headerValue'>{aluno.name}</div>
            </div>
          </div>
          <div className={dropDown? 'body bodyOpen' : 'body'}>
            <div className='data'>
              <div className='dataInfo'>
                <div className='dataValueLeft'>{aluno.name}</div>
                <div className='dataValueRight'>{insc.course.name}</div>
              </div>
              <div className='dataInfo'>
                <div className='dataValueLeft'>{aluno.document}</div>
                <div className='dataValueRight'>{insc.course.qty_class} Aula(s)</div>
              </div>
              <div className='dataInfo'>
                <div className='dataValueLeft'>{diaSemana(insc.hour.teacher_working_hour.week_day)}</div>
                <div className='dataValueRight'>{formatarHorario(insc.hour.from_hour)}</div>
              </div>
            </div>
            <div className='aprovar'>
              <div className='aprovarText'>Aprovar Inscrição?</div>
              <button 
                onClick={() => {
                  setShow(false)
                  auth(insc.id, true)
                }}
              >
                SIM
              </button>
              <button 
                onClick={() => {
                  setShow(false)
                  auth(insc.id, false)
                }}
              >
                NÃO
              </button>
            </div>
          </div>
        </div>

      }
    </>
  )
}
