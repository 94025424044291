import React, { useState } from 'react'

import { Link, useNavigate } from 'react-router-dom'
import Loading from '../../components/Loading'
import InputMask from "react-input-mask";
import Button from '@mui/material/Button';
import { Formik } from 'formik';
import * as Yup from 'yup';

import './Login.scss'
import logo from '../../assets/sofieto.png'
import api from '../../service/api'
import ErrorMessage from '../../components/ErrorMessage';
import PasswordInput from '../../components/PasswordInput';
import SnackAlert from '../../components/Alertas/SnackAlert';


export default function Login(){

  const navigate = useNavigate();

  const [ loading, setLoading ] = useState(false)
  const [ openSnack, setOpenSnack ] = useState(false)
  const [ msgErr, setMsgErr ] = useState('')

  const initialValues = {
    document: '',
    password: '',
  }

  const validationSchema = Yup.object({
    document: Yup.string().required("Campo CPF é obrigatório").matches(/\d{3}.\d{3}.\d{3}-\d{2}/,"CPF está incompleto"),
    password: Yup.string().required("Campo Senha é obrigatório"),
  })

  async function handleLogin(values){
    setLoading(true)
    try {
      let payload = {
        document: values.document,
        password: values.password,
      }

      let res = await api.post('/login', payload)
  
      if(res.status === 200){
        
        if(res.data.user.lookup_role_id === 2){
          sessionStorage.setItem('userId', res.data.user.id)
          sessionStorage.setItem('user', JSON.stringify(res.data.user))
          sessionStorage.setItem('seshToken', res.data.token)
          sessionStorage.setItem('openAviso', 'true')

          navigate('/HomeUser')
        } else if(res.data.user.lookup_role_id === 1){
          sessionStorage.setItem('teacherId', res.data.user.id)
          sessionStorage.setItem('user', JSON.stringify(res.data.user))
          sessionStorage.setItem('seshToken', res.data.token)

          navigate('/HomeAdm')
        } else {
          setMsgErr('Erro de login, tente novamente')
          setOpenSnack(true)
        }
      }
    } catch (err) {
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 401){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
    } finally {
      setLoading(false)
    }
  }

  return(
    <div id='login-container'>
      { loading&&
        <Loading />
      }
      <div className='imgContainer'>
        <img src={logo} alt='logo acordeon'/>
      </div>
      <div className='formDiv'>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={values => handleLogin(values) }
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <InputMask
                id='document'
                name='document'
                mask="999.999.999-99"
                placeholder='CPF'
                value={props.values.document}
                onChange={props.handleChange}
              />
              {props.errors.document&& props.touched.document&& <ErrorMessage error={props.errors.document} />}

              <PasswordInput id={'password'} name={'password'} holder={'Senha'} value={props.values.password} onChange={props.handleChange} white/>
              {props.errors.password&& props.touched.password&& <ErrorMessage error={props.errors.password} />}
              
              <Button className='general-button' variant="outlined" type='submit'>Entrar</Button>
            </form>
          )}
        </Formik>
        <div className='forgot'>
          <Link className='forgot' to='/ForgotPassword'>Esqueceu sua senha?</Link>
        </div>
      </div>

      {/* <div className='bottom'>
        Ainda não possui uma conta? <Link className='link' to='/Cadastro/Dados'>Cadastre-se</Link>
      </div> */}

      <SnackAlert open={openSnack} setOpen={setOpenSnack} type={'error'} msg={msgErr} />
    </div>
  )
}