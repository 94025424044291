import React, { useState, useEffect } from 'react';

import NovoAlunoItem from '../../components/NovoAlunoItem';

import Loading from '../../components/Loading'
import Menu from '../../components/Menu';
import Header from '../../components/Header';
import api from '../../service/api';
import SnackAlert from '../../components/Alertas/SnackAlert';

import './AceitarAluno.scss';

export default function AceitarAluno() {
  
  const [ alunos, setAlunos ] = useState([])
  const [ menu, openMenu ] = useState(false)
  
  const [ loading, setLoading ] = useState(false)

  const [ openSnack, setOpenSnack ] = useState(false)
  const [ msgErr, setMsgErr ] = useState('')
  const [ snackType, setSnackType ] = useState('')

  async function getAlunos(){
    setLoading(true)
    try{
      let res = await api.get('/user?auth=false')

      console.log('res :: ', res.data)

      if(res.status === 200){
        setAlunos(res.data.users)
      }
    } catch(err){
      setSnackType('error')
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 404){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    } finally {
      setLoading(false)
    }
  }

  async function authorized(id, authorized, index){
    setLoading(true)
    
    try {
      let payload = { 
        id,
        authorized, 
      }
      let res = await api.put('/authorize-user', payload)     
      
      if(res.status === 200){
        setSnackType('success')
        setMsgErr(res.data.message)
        setOpenSnack(true)
        removerLista(index)
      }

    } catch (err) {
      setSnackType('error')
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 404){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    } finally {
      setLoading(false)
    }
  }

  function removerLista(index){
    let aux = [...alunos]

    if(index !== -1){
      aux.splice(index,1)
      setAlunos(aux)
    }
  }

  useEffect(() => {
    getAlunos()
  }, [])

  return (
    <div id='aceitar-container'>
      { loading&&
        <Loading />
      }
      <Header title={'NOVOS ALUNOS'} menu={menu} openMenu={openMenu} />
      { alunos.length === 0&&
        <div className='emptyList'>Não há nenhum aluno para aprovação...</div>
      }
      { alunos.map((a, index) => (
        <NovoAlunoItem key={a.id} i={index} aluno={a} auth={authorized}/>
      ))}
      <Menu open={menu} closeMenu={openMenu} adm={true}/> 
      <SnackAlert open={openSnack} setOpen={setOpenSnack} type={snackType} msg={msgErr} />
    </div>
  )
}
