import React from 'react'
import './Loading.scss'

import ScaleLoader from "react-spinners/ScaleLoader";

export default function Loading(load) {
  return (
    <div className='loading'>
      <ScaleLoader color={'#000000'} loading={true} css={''} size={150} />
    </div>
  )
}
