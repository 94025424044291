import React from 'react';

import { FiArrowLeft, FiMenu } from 'react-icons/fi';

import { useNavigate } from 'react-router-dom'

import './Header.scss'

import logo from '../../assets/header-logo.png'

export default function Header({title, menu, openMenu, goBack}) {
  const navigate = useNavigate()
  return (
    <div id='header-container'>
      { goBack?
        <button onClick={() => navigate(-1)} ><FiArrowLeft size={20} color='#201715'/></button>
        :
        <button onClick={() => openMenu(!menu)} ><FiMenu size={20} color='#201715'/></button>
      }
      <h3>{title}</h3>
      <div className='imgHeader'>
        <a href='https://www.sofieto.com.br/' target="_blank" rel="noopener noreferrer">
          <img src={logo} alt='logo acordeon'/>
        </a>
      </div>
    </div>
  )
}