import React, {useState, useEffect} from 'react'
import './AlterarHorario.scss'

import Header from '../../components/Header'
import Loading from '../../components/Loading'
import ErrorMessage from '../../components/ErrorMessage'

import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom'
import api from '../../service/api';
import * as validations from '../../utils/validations';
import formatarHorario from '../../utils/formatarHorario';
import SnackAlert from '../../components/Alertas/SnackAlert';

export default function AlterarCurso() {

  const navigate = useNavigate();

  const [ user, setUser ] = useState('')
  const [ week_day, setWeek_day ] = useState('')
  const [ user_course_id, setUser_course_id ] = useState('')
  const [ hour_id, setHour_id ] = useState('')

  const [ errors, setErrors ] = useState({})
  
  const [ hours, setHours ] = useState([])
  const [ loading, setLoading ] = useState(false)

  const [ openSnack, setOpenSnack ] = useState(false)
  const [ msgErr, setMsgErr ] = useState('')
  const [ snackType, setSnackType ] = useState('')

  async function handleAlterCourse(event){
    event.preventDefault()
    if(validate()){
      setLoading(true)
      try{
        let payload = {
          user_course_id,
          week_day,
          hour_id,
        }

        let res = await api.put('/change-all-events', payload)

        if(res.status === 200){
          sessionStorage.removeItem('editarHorario')
          setSnackType('success')
          setMsgErr(res.data.message)
          setOpenSnack(true)

          navigate('/HomeAdm')
        }

      } catch(err) {
        setSnackType('error')
        if(err.message === 'Network Error'){
          setMsgErr('Não foi possível contatar o servidor...')
          setOpenSnack(true)
        }
        let error = err.response
        if(error.status === 400){
          setMsgErr(error.data.message)
          setOpenSnack(true)
        }
      } finally {
        setLoading(false)
      }
    }

  }

  function getWeekDay(day){
    if(day === 1)return "Segunda"
    if(day === 2)return "Terça"
    if(day === 3)return "Quarta"
    if(day === 4)return "Quinta"
    if(day === 5)return "Sexta"
    if(day === 6)return "Sábado"
  }

  function validate(){
    let err = {}
    if(validations.id(week_day)){
      err.weekday = 'Necessário selecionar um dia da semana'
    }
    if(validations.id(hour_id) && week_day !== ''){
      err.hour = 'Necessário selecionar um horário'
    }
    setErrors(err)

    if(JSON.stringify(err) === JSON.stringify({})){
      return true
    }
  }

  function getUser(){
    let user = JSON.parse(sessionStorage.getItem('editarHorario'))
    console.log(user)
    setUser(user)
    setUser_course_id(user.curso.id)
    getHours(user.curso.hour.teacher_working_hour.teacher_id)
  }
  
  async function getHours(id){
    try {
      let res = await api.get('/workingHours', {
        headers: {
          id: id
        }
      })

      if(res.status === 200){
        setHours(res.data)
      }
    } catch (err) {
      setSnackType('error')
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    } finally{
      setLoading(false)
    }
  }

  useEffect(() => {
    getUser()
  },[])

  return (
    <div id='alterhorario-container'>

      { loading&&
        <Loading />
      }
      <Header title={'ALTERAR HORÁRIO'} goBack={true} />
      
      <div className='title'>Selecione um novo horário para seu curso:</div>
      <div className='formDiv'>
        <form onSubmit={handleAlterCourse}>

          { user&&
            <div className='infoView'>
              <div className='infoData'>{`${user.aluno} - ${user.curso.course.name}`}</div>
              <div className='infoData'>{`${getWeekDay(user.curso.hour.teacher_working_hour.week_day)} - ${formatarHorario(user.curso.hour.from_hour)}`}</div>
            </div>
          }

          <div className='weekday'>
            <div onClick={() => setWeek_day(1)} className={`dayItem ${ week_day === 1&& 'dayItem-selected'}`}>SEG</div>
            <div onClick={() => setWeek_day(2)} className={`dayItem ${ week_day === 2&& 'dayItem-selected'}`}>TER</div>
            <div onClick={() => setWeek_day(3)} className={`dayItem ${ week_day === 3&& 'dayItem-selected'}`}>QUA</div>
            <div onClick={() => setWeek_day(4)} className={`dayItem ${ week_day === 4&& 'dayItem-selected'}`}>QUI</div>
            <div onClick={() => setWeek_day(5)} className={`dayItem ${ week_day === 5&& 'dayItem-selected'}`}>SEX</div>
            <div onClick={() => setWeek_day(6)} className={`dayItem ${ week_day === 6&& 'dayItem-selected'}`}>SAB</div>
          </div>
          {errors.weekday !== ''&& <ErrorMessage error={errors.weekday} />}

          <div className='time'>
            { hours.map(h => h.week_day === week_day&&(
              <>
                { h.hours.map(hour => (
                  <>
                    { hour.is_available&&
                      <div key={hour.id} onClick={() => setHour_id(hour.id)} className={`timeStamp ${ hour_id === hour.id&& 'timeStamp-selected'}`}>{formatarHorario(hour.from_hour)}</div>
                    }
                  </>
                ))}
              </>
            ))}
          </div>
          {errors.hour&&week_day!==''&& <ErrorMessage error={errors.hour} />}

          <Button className='general-button' variant="outlined" type='submit'>Alterar horário</Button>
        </form>
      </div>
      <SnackAlert open={openSnack} setOpen={setOpenSnack} type={snackType} msg={msgErr} />
    </div>
  )
}
