import React from 'react'

import { FiEdit, FiCheck, FiX } from 'react-icons/fi';
import formatarHorario from '../../utils/formatarHorario';

import './UserEventCard.scss'

export default function UserEventCard({event, reagendar}) {

  function formatarData(data){
    if(event){
      return (data.split('-').reverse().join('/'));
    }
  }

  return (
    <div className='details'>
      { event.vacation_id&&
        <>
          <div className='detailsItem'>
            <div className='cursoText'>Ferias</div>
            <div className='hourText'>{formatarData(event.month_day)}</div>
          </div>
        </>
      }
      { event.type=='national'&&
        <>
          <div className='detailsItem'>
            <div>
              <div className='cursoText'>Feriado</div>
              <div className='nameText'>{event.name}</div>
              <div className='hourText'>{formatarData(event.date)}</div>
            </div>
          </div>
        </>
      }
      { event.user_course&&
        <>
          <div className='detailsItem'>
            <div>
              <div className='cursoText'>{`${event.user_course.course.name}`}</div>
              <div className='hourText'>{`${formatarHorario(event.hour.from_hour)}`}</div>
            </div>
          
          { event.attendance === true&&
            <div className='editBtn'>
              <FiCheck size={22} color={'#057dcd'}/>
            </div>
          }
          { event.attendance === false&&
            <div className='editBtn'>
              <FiX size={22} color={'#ff0000'}/>
            </div>
          }
          </div>
          { event.attendance === null&&
            <div onClick={() => reagendar(event)} className='reschedule'>
              Reagendar
            </div>
          }
        </>
      }
    </div>
  )
}
