import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'

import CursoAlunoItem from '../../components/CursoAlunoItem';

import Header from '../../components/Header';

import './MeusCursos.scss';

export default function MeusCursos() {

  const navigate = useNavigate();
  
  const [ user, setUser ] = useState('')
  const [ cursos, setCursos ] = useState([])

  async function getCursos(){
    let user = JSON.parse(sessionStorage.getItem('user'))

    setUser(user)
    setCursos(user.user_courses)
  }

  function editCurso(aluno, curso){
    let payload = {
      aluno,
      curso,
    }

    sessionStorage.setItem('editarHorario', JSON.stringify(payload))

    navigate('/AlterarHorario')
  }

  useEffect(() => {
    getCursos()
  }, [])

  return (
    <div id='meuscursos-container'>
      <Header title={'MEUS CURSOS'} goBack={true}/>
      <div className='title'>Selecione um curso que deseja alterar o horário:</div>
      { cursos.length === 0&&
        <div className='emptyList'>Você não está cadastro em nenhum curso...</div>
      }
      { cursos.map(c => (
        <CursoAlunoItem aluno={user.name} curso={c} editar={editCurso}/>
      ))}
    </div>
  )
}
