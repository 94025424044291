import React, { useState } from 'react'
import Modal from '@mui/material/Modal'

import * as validations from '../../../utils/validations';

import './DeletarFerias.scss'
import formatarHorario from '../../../utils/formatarHorario';

export default function DeletarFerias({modal, modalClose, vacation, deletar}) {
  
  function format(dt){
    return dt.split('-').reverse().join('/')
  }

  return (
    <Modal
      open={modal}
      onClose={modalClose}
    >    
      <div id='deletarFerias-container'>
        <div className='box'>
          Cancelar Ferias?

          { vacation&&
            <div className='horario'>{format(vacation.vacation_start)} á {format(vacation.vacation_end)}</div>
          }

          <div className='btnContainer'>
            <button className='cancelar' onClick={() => modalClose(false)}>Cancelar</button>
            <button className='confirmar' onClick={() => deletar(vacation.id)}>Confirmar</button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
