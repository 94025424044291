import React, { useState, useEffect } from 'react'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button';
import { FiX } from 'react-icons/fi';


import Loading from '../../Loading'
import ErrorMessage from '../../ErrorMessage';
import * as validations from '../../../utils/validations'
import formatarHorario from '../../../utils/formatarHorario';
import SnackAlert from '../../Alertas/SnackAlert';

import './Reagendamento.scss';
import api from '../../../service/api';

export default function Reagendamento({modal, modalClose, event, success, admin}) {

  const [ month_day, setMonth_day ] = useState('')
  const [ hour_id, setHour_id ] = useState('')
  
  const [ weekDay, setWeekDay ] = useState('')
  const [ hours, setHours ] = useState([])
  
  const [ loading, setLoading ] = useState(false)
  const [ errors, setErrors ] = useState({})

  const [ openSnack, setOpenSnack ] = useState(false)
  const [ msgErr, setMsgErr ] = useState('')
  
  function selectDate(v){
    let aux = `${v} 00:00:00`

    let newDate = new Date(aux)
    setWeekDay(newDate.getUTCDay())
    setMonth_day(newDate)
    getTeacherHours(event.hour.teacher_working_hour.teacher_id, v)
  }

  function formatarData(data){
    if(event !== ''){
      return (data.split('-').reverse().join('/'));
    }
  }

  async function getTeacherHours(id, date){
    setLoading(true)
    try {
      let res = await api.get(`/workingHours?date=${date}`, {
        headers: {
          id: id,
          admin: admin
        }
      })

      if(res.status === 200){
        console.log(date)
        console.log(res)
        setHours(res.data)
      }
    }catch(err){
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    } finally {
      setLoading(false)
    }
  }

  function validateForm(){
    setErrors({})
    let err = {}
    if(month_day === ''){
      err.month_day = 'Selecione uma data'
    }
    if(validations.id(hour_id) && month_day!== ''){
      err.hour_id = 'Selecione um horário'
    }
    setErrors(err)


    if(JSON.stringify(err) === JSON.stringify({})){
      alterarEvento()
    }
  }

  async function alterarEvento(){
    setLoading(true)
    try {
      let payload = { 
        month_day,
        hour_id
      }
      let res = await api.put('/events', payload, {
        headers: {
          id: event.id
        }
      })
      if(res.status === 200){
        success(res.data.message)
      }

    } catch (err) {
      console.log('hello')
      console.log('err', err.message)
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    } finally { 
      setMonth_day('')
      setHour_id('')
      setLoading(false)
    }
  }

  /* useEffect(() =>{
    
    if(event){
      getTeacherHours(event.hour.teacher_working_hour.teacher_id)
    }
  }, [event]) */

  return (
    <Modal
      open={modal}
      onClose={modalClose}
    >
      <div id='reagendamento-container'>
        { loading&&
          <Loading />
        }
        <div className='box'>
          <div className='header'>
            Reagendamento
            <button className='close' onClick={() => modalClose(false)}><FiX size={25} color='gray' /></button>
          </div>
          <div className='previous'>
            <div className='initialTitle'>Data inicial:</div>
            { event&&
              <div className='initialDate'>{`${formatarData(event.month_day)} ${formatarHorario(event.hour.from_hour)}`}</div>
            }
          </div>
          <div className='new'>
            <div className='newTitle'>Nova data:</div>
          </div>
          <input placeholder='Data de Nascimento' type='date' onChange={v => selectDate(v.target.value)} />
          {errors.month_day&& <ErrorMessage error={errors.month_day} />}

          <div className='time'>
          { hours.map(h => h.week_day === weekDay&&(
            <>
              { h.hours.map(hour => (
                <>
                  <div key={hour.id} onClick={() => setHour_id(hour.id)} className={`timeStamp ${ hour_id === hour.id&& 'timeStamp-selected'}`}>{formatarHorario(hour.from_hour)}</div>
                </>
              ))}
            </>
          ))}
          </div>
          {(errors.hour_id&& month_day!== '')&& <ErrorMessage error={errors.hour_id} />}

          
          <Button onClick={() => validateForm()} variant='outlined' className='general-button saveBtn'>
            Salvar
          </Button>
        </div>
        <SnackAlert open={openSnack} setOpen={setOpenSnack} type={'error'} msg={msgErr} />
      </div>
    </Modal>
  )
}
