import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button';

import Header from '../../components/Header'
import Menu from '../../components/Menu'
import Loading from '../../components/Loading'
import SnackAlert from '../../components/Alertas/SnackAlert';

import api from '../../service/api'

import './DefinirHorarios.scss'


export default function DefinirHorarios() {

  const navigate = useNavigate()

  const [ loading, setLoading ] = useState(false)
  const [ menu, openMenu ] = useState(false)

  const [ days, setDays ] = useState([])
  const [ teacher_id, setTeacher_id ] = useState('')

  const [ openSnack, setOpenSnack ] = useState(false)
  const [ msgErr, setMsgErr ] = useState('')

  // Segunda
  const [ segIni, setSegIni ] = useState('')
  const [ segFim, setSegFim ] = useState('')
  const [ segDur, setSegDur ] = useState('')
  // Terça
  const [ terIni, setTerIni ] = useState('')
  const [ terFim, setTerFim ] = useState('')
  const [ terDur, setTerDur ] = useState('')
  // Quarta
  const [ quaIni, setQuaIni ] = useState('')
  const [ quaFim, setQuaFim ] = useState('')
  const [ quaDur, setQuaDur ] = useState('')
  // Quinta
  const [ quiIni, setQuiIni ] = useState('')
  const [ quiFim, setQuiFim ] = useState('')
  const [ quiDur, setQuiDur ] = useState('')
  // Sexta
  const [ sexIni, setSexIni ] = useState('')
  const [ sexFim, setSexFim ] = useState('')
  const [ sexDur, setSexDur ] = useState('')
  // Sabado
  const [ sabIni, setSabIni ] = useState('')
  const [ sabFim, setSabFim ] = useState('')
  const [ sabDur, setSabDur ] = useState('')

  function toggleDay(d){
    let aux = [...days]

    if(!aux.includes(d)){
      aux.push(d)

      setDays(aux)
    }else{
      aux.splice(aux.indexOf(d), 1)

      setDays(aux)
    }
  }

  async function definirHorarios(){
    setLoading(true)
    let payload = []

    try {
      if(days.includes(1)){
        payload.push({
          teacher_id,
          week_day: 1,
          from_hour: parseInt(segIni),
          to_hour: parseInt(segFim),
          hour_span: parseInt(segDur),
        })
      }
      if(days.includes(2)){
        payload.push({
          teacher_id,
          week_day: 2,
          from_hour: parseInt(terIni),
          to_hour: parseInt(terFim),
          hour_span: parseInt(terDur),
        })
      }
      if(days.includes(3)){
        payload.push({
          teacher_id,
          week_day: 3,
          from_hour: parseInt(quaIni),
          to_hour: parseInt(quaFim),
          hour_span: parseInt(quaDur),
        })
      }
      if(days.includes(4)){
        payload.push({
          teacher_id,
          week_day: 4,
          from_hour: parseInt(quiIni),
          to_hour: parseInt(quiFim),
          hour_span: parseInt(quiDur),
        })
      }
      if(days.includes(5)){
        payload.push({
          teacher_id,
          week_day: 5,
          from_hour: parseInt(sexIni),
          to_hour: parseInt(sexFim),
          hour_span: parseInt(sexDur),
        })
      }
      if(days.includes(6)){
        payload.push({
          teacher_id,
          week_day: 6,
          from_hour: parseInt(sabIni),
          to_hour: parseInt(sabFim),
          hour_span: parseInt(sabDur),
        })
      }

      let res = await api.post('/workingHours', payload)

      console.log('res:', res)

      if(res.status === 200){
        navigate('/HomeAdm')
      }
    } catch (err) {
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    } finally {
      setLoading(false)
    }


  }

  async function getTeacher(){
    const id = sessionStorage.getItem('teacherId')

    setTeacher_id(parseInt(id))
  } 

  useEffect(() => {
    getTeacher()
  }, [])

  return (
    <div id='horarios-container'>
      { loading&&
        <Loading />
      }
      <Header title={'EXPEDIENTE'} menu={menu} openMenu={openMenu} />
      
      <div className='titleContainer'>
        <div className='title'>Defina seus horários de trabalho</div>
        <div className='title'>e a duração das aulas</div>
      </div>

      <div className='daysContainer'>

        <div className='dayBox'>
          <div className='fakeItem' />
          <div className='label'>Inicio de expediente:</div>
          <div className='label'>Fim de expediente:</div>
          <div className='label'>Duração das aulas(Minutos):</div>
        </div>
        <div className='dayBox'>
          <div onClick={() => toggleDay(1)} className={`dayItem ${ days.includes(1)&& 'dayItem-selected'}`}>SEG</div>
          { days.includes(1)&&
            <>
              <div className='value'>
                <input /* type='number' */ maxLength={2} onChange={v => setSegIni(v.target.value)} />
                h
              </div>
              <div className='value'>
                <input /* type='number' */ maxLength={2} onChange={v => setSegFim(v.target.value)} />
                h
              </div>
              <div className='value'>
                <input /* type='number' */ maxLength={2} onChange={v => setSegDur(v.target.value)} />
                m
              </div>
            </>
          }
        </div>
        <div className='dayBox'>
          <div onClick={() => toggleDay(2)} className={`dayItem ${ days.includes(2)&& 'dayItem-selected'}`}>TER</div>
          { days.includes(2)&&
            <>
              <div className='value'>
                <input /* type='number' */ maxLength={2} onChange={v => setTerIni(v.target.value)} />
                h
              </div>
              <div className='value'>
                <input /* type='number' */ maxLength={2} onChange={v => setTerFim(v.target.value)} />
                h
              </div>
              <div className='value'>
                <input /* type='number' */ maxLength={2} onChange={v => setTerDur(v.target.value)} />
                m
              </div>
            </>
          }
        </div>
        <div className='dayBox'>
          <div onClick={() => toggleDay(3)} className={`dayItem ${ days.includes(3)&& 'dayItem-selected'}`}>QUA</div>
          { days.includes(3)&&
            <>
              <div className='value'>
                <input /* type='number' */ maxLength={2} onChange={v => setQuaIni(v.target.value)} />
                h
              </div>
              <div className='value'>
                <input /* type='number' */ maxLength={2} onChange={v => setQuaFim(v.target.value)} />
                h
              </div>
              <div className='value'>
                <input /* type='number' */ maxLength={2} onChange={v => setQuaDur(v.target.value)} />
                m
              </div>
            </>
          }
        </div>
        <div className='dayBox'>
          <div className='fakeItem' />
          <div className='label'>Inicio de expediente:</div>
          <div className='label'>Fim de expediente:</div>
          <div className='label'>Duração das aulas(Minutos):</div>
        </div>
        <div className='dayBox'>
          <div onClick={() => toggleDay(4)} className={`dayItem ${ days.includes(4)&& 'dayItem-selected'}`}>QUI</div>
          { days.includes(4)&&
            <>
              <div className='value'>
                <input /* type='number' */ maxLength={2} onChange={v => setQuiIni(v.target.value)} />
                h
              </div>
              <div className='value'>
                <input /* type='number' */ maxLength={2} onChange={v => setQuiFim(v.target.value)} />
                h
              </div>
              <div className='value'>
                <input /* type='number' */ maxLength={2} onChange={v => setQuiDur(v.target.value)} />
                m
              </div>
            </>
          }
        </div>
        <div className='dayBox'>
          <div onClick={() => toggleDay(5)} className={`dayItem ${ days.includes(5)&& 'dayItem-selected'}`}>SEX</div>
          { days.includes(5)&&
            <>
              <div className='value'>
                <input /* type='number' */ maxLength={2} onChange={v => setSexIni(v.target.value)} />
                h
              </div>
              <div className='value'>
                <input /* type='number' */ maxLength={2} onChange={v => setSexFim(v.target.value)} />
                h
              </div>
              <div className='value'>
                <input /* type='number' */ maxLength={2} onChange={v => setSexDur(v.target.value)} />
                m
              </div>
            </>
          }
        </div>
        <div className='dayBox'>
          <div onClick={() => toggleDay(6)} className={`dayItem ${ days.includes(6)&& 'dayItem-selected'}`}>SAB</div>
          { days.includes(6)&&
            <>
              <div className='value'>
                <input /* type='number' */ maxLength={2} onChange={v => setSabIni(v.target.value)} />
                h
              </div>
              <div className='value'>
                <input /* type='number' */ maxLength={2} onChange={v => setSabFim(v.target.value)} />
                h
              </div>
              <div className='value'>
                <input /* type='number' */ maxLength={2} onChange={v => setSabDur(v.target.value)} />
                m
              </div>
            </>
          }
        </div>

      </div>

      <Button variant='outlined' className='general-button save' onClick={() => definirHorarios()}>Salvar</Button>
      <Menu open={menu} closeMenu={openMenu} adm={true}/>
      <SnackAlert open={openSnack} setOpen={setOpenSnack} type={'error'} msg={msgErr} />
    </div>
  )
}