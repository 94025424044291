import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button';

import { FiEdit, FiTrash2 } from 'react-icons/fi';

import Header from '../../components/Header';
import Menu from '../../components/Menu';
import Loading from '../../components/Loading';
import CriarHorario from '../../components/Modals/CriarHorario'
import EditarHorario from '../../components/Modals/EditarHorario'
import DeletarHorario from '../../components/Modals/DeletarHorario'
import SnackAlert from '../../components/Alertas/SnackAlert';
import api from '../../service/api';

import formatarHorario from '../../utils/formatarHorario';

import './HorariosAdm.scss';

export default function HorariosAdm() {
  
  const navigate = useNavigate();
  
  const [ prf_id, setPrf_id ] = useState('')
  const [ horarios, setHorarios ] = useState([])

  const [ day, setDay ] = useState('')
  const [ selectedHours, setSelectedHours ] = useState([])

  const [ createHour, setCreateHour ] = useState(false)
  const [ editHour, setEditHour ] = useState(false)
  const [ selectEdit, setSelectEdit ] = useState(false)
  const [ toEdit, setToEdit ] = useState('')
  const [ confirmDelete, setConfirmDelete ] = useState(false)
  const [ toDelete, setToDelete ] = useState('')
  const [ loading, setLoading ] = useState(false)
  const [ menu, openMenu ] = useState(false)

  const [ openSnack, setOpenSnack ] = useState(false)
  const [ msgErr, setMsgErr ] = useState('')
  const [ snackType, setSnackType ] = useState('')

  async function handleSalvar(){
    setLoading(true)
    try {
      let res = await api.put('/change-hours', selectedHours)

      if(res.status === 200){
        navigate('/HomeAdm')
      }
    } catch (err) {
      setSnackType('error')
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    } finally {
      setLoading(false)
    }
  
  }

  function getProfessor(){
    let prf = sessionStorage.getItem('teacherId')
    setPrf_id(prf)
  }

  async function getHours(){
      
    try {
      let res = await api.get('/workingHours', {
        headers: {
          id: 1,
          admin: true
        }
      })

      if(res.status === 200){
        setHorarios(res.data)
      }
    } catch (err) {
      setSnackType('error')
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    } finally {
      setLoading(false)
    }
  } 

  function manageSelectedHours(id){
    let aux = [...selectedHours]

    if(!aux.includes(id)){
      aux.push(id)

      setSelectedHours(aux)
    }else{
      aux.splice(aux.indexOf(id), 1)

      setSelectedHours(aux)
    }
  }

  async function newHour(from_hour){
    let id = ''

    for (const w of horarios){
      if(w.week_day === day){
        id = w.id
      }
    }

    let payload = {
      id,
      from_hour,
      is_available: true
    }
    
    try{
      let res = await api.post('/hour', payload)
      if(res.status === 201){
        setCreateHour(false)
        setSnackType('success')
        setMsgErr(res.data.message)
        setOpenSnack(true)
        getHours()
      }
    }catch(err){
      setSnackType('error')
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    }

  }

  async function alterHour(from_hour, id){
    try{
      let payload = {
        id,
        from_hour,
      }

      let res = await api.put('/hour', payload)

      if(res.status === 201){
        setEditHour(false)
        setSelectEdit(false)
        setSnackType('success')
        setMsgErr(res.data.message)
        setOpenSnack(true)
        getHours()
      }
    }catch(err){
      setSnackType('error')
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 404){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    }
  }

  async function deleteHour(id){
    try{
      let payload = {
        id,
      }

      let res = await api.put('/disable-hour', payload)

      if(res.status === 201){
        setConfirmDelete(false)
        setSelectEdit(false)
        setSnackType('success')
        setMsgErr(res.data.message)
        setOpenSnack(true)
        getHours()
      }
    }catch(err){
      setSnackType('error')
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 404){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    }
  }
  
  useEffect(() => {
    setLoading(true)
    getProfessor()
    getHours()
  }, [])

  
  return (
    <div id='horasadm-container'>
      { loading&&
        <Loading />
      }
      <Header title={'GERENCIAR HORÁRIOS'} menu={menu} openMenu={openMenu} />
      <CriarHorario modal={createHour} modalClose={setCreateHour} novo={newHour}/>
      <EditarHorario modal={editHour} modalClose={setEditHour} hour={toEdit} edit={alterHour}/>
      <DeletarHorario modal={confirmDelete} modalClose={setConfirmDelete} hour={toDelete} deletar={deleteHour}/>
      
      <div className='titleContainer'>
        <div className='title'>Selecione os horários da semana</div>
        <div className='title'>que deseja {selectEdit? 'alterar':'indisponibilizar'}</div>
      </div>
      
      <div className='weekday'>

        <div onClick={() => setDay(1)} className={`dayItem ${ day === 1&& 'dayItem-selected'}`}>SEG</div>
        <div onClick={() => setDay(2)} className={`dayItem ${ day === 2&& 'dayItem-selected'}`}>TER</div>
        <div onClick={() => setDay(3)} className={`dayItem ${ day === 3&& 'dayItem-selected'}`}>QUA</div>
        <div onClick={() => setDay(4)} className={`dayItem ${ day === 4&& 'dayItem-selected'}`}>QUI</div>
        <div onClick={() => setDay(5)} className={`dayItem ${ day === 5&& 'dayItem-selected'}`}>SEX</div>
        <div onClick={() => setDay(6)} className={`dayItem ${ day === 6&& 'dayItem-selected'}`}>SAB</div>
    
      </div>

      { day !== ''&&
        <div className='buttons'>
          <button className="btnHr" onClick={() => setCreateHour(true)}>Criar</button>
          <button className={`btnHr ${selectEdit&&'editando'}`} onClick={() => {
              setSelectEdit(!selectEdit) 
              setSelectedHours([])
            }}
          >
            Gerenciar
          </button>
        </div>
      }

      <div className='horarios'>
      { horarios.map(h => h.week_day === day&&(
        <>
          { h.hours.map(hour => (
            <>
              { selectEdit?
                <div className='editStamp'>
                  <div className='editBtn'
                    onClick={() => {
                      setToEdit(hour)
                      setEditHour(true)
                    }}
                  >
                    <FiEdit className='icon' size={14} color={'#faffff'} />
                  </div>
                  <div className='editTime'>{formatarHorario(hour.from_hour)}</div>
                  <div className='deleteBtn'
                    onClick={() => {
                      setToDelete(hour)
                      setConfirmDelete(true)
                    }}
                  >
                    <FiTrash2 className='icon' size={14} color={'#FAFFFF'} />
                  </div>
                </div>
                :
                <div onClick={() => manageSelectedHours(hour.id)} className={`timeStamp ${(!hour.is_available && !selectedHours.includes(hour.id))&& 'timeStamp-deactivated'} ${ selectedHours.includes(hour.id)&& 'timeStamp-selected'}`}>{formatarHorario(hour.from_hour)}</div>
              }
            </>
          ))}
          
        </>
      ))}
      </div>
      <Button disabled={selectedHours.length === 0&& true} variant='outlined' className={`general-button save ${selectedHours.length === 0&& 'off'}`} onClick={() => handleSalvar()}>Salvar</Button>
      <Menu open={menu} closeMenu={openMenu} adm={true}/>
      <SnackAlert open={openSnack} setOpen={setOpenSnack} type={snackType} msg={msgErr} />
    </div>
  )
}
