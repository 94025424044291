import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button';

import Loading from '../../components/Loading'
import Header from '../../components/Header'
import SnackAlert from '../../components/Alertas/SnackAlert';
import api from '../../service/api';

import './EditAviso.scss'

export default function EditAviso() {

  const navigate = useNavigate();

  const [ notes, setNotes ] = useState('')

  const [ loading, setLoading ] = useState(false)

  const [ openSnack, setOpenSnack ] = useState(false)
  const [ msgErr, setMsgErr ] = useState('')

  const count = 240

  async function saveAvisos(){
    try {
      setLoading(true)
      let payload = {
        notes
      }
      
      let res = await api.put('/notes', payload)

      if(res.status === 200){
        navigate('/HomeAdm')
      }
    } catch (err) {
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    } finally {
      setLoading(false)
    }
  }

  async function getAvisos(){
    try{
      setLoading(true)

      let res = await api.get('/notes')
      console.log(res.data)
      if(res.status){
        setNotes(res.data.note.notes)
      }
    } catch(err) {
      console.log('err', err.message)
      console.log('err', err.response)
    }finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getAvisos()
  }, [])

  return (
    <div id='editaviso-container'>
      { loading&&
        <Loading />
      }
      <Header title={'EDITAR AVISOS'} goBack={true}/>
      <div className='avisos-label'>
        Avisos:
      </div>

      <div className='avisos-text'>
        <textarea
          placeholder='Digite uma descrição para o curso...'
          rows={15}
          maxLength={count}
          value={notes}
          onChange={v => setNotes(v.target.value)}
        />
        <div className='textarea-count'>{`${notes.length} / ${count}`}</div>
      </div>

      <Button className='general-button' variant="outlined" onClick={() => saveAvisos()}>{'Atualizar'}</Button>

      <SnackAlert open={openSnack} setOpen={setOpenSnack} type={'error'} msg={msgErr} />
    </div>
  )
}
