export default function formatarHorario(t){
  let time = (t / 60).toFixed(2)

  let formated = time.toString().split('.')
  let h = formated[0]
  let m = formated[1]

  if(h.length === 1){
    h = `0${h}`
  }
  if(m.toString().includes('.')){
    m = parseInt(m)
  }
  
  return `${h}:${m}`
}