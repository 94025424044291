import React, { useState, useEffect } from 'react';

import NovaInscricaoItem from '../../components/NovaInscricaoItem';

import Loading from '../../components/Loading'
import Menu from '../../components/Menu';
import Header from '../../components/Header';
import SnackAlert from '../../components/Alertas/SnackAlert';
import api from '../../service/api';

import './AceitarInscricao.scss';

export default function AceitarIncricao() {
  
  const [ inscricoes, setInscricoes ] = useState([])
  const [ menu, openMenu ] = useState(false)
  
  const [ loading, setLoading ] = useState(false)

  const [ openSnack, setOpenSnack ] = useState(false)
  const [ msgErr, setMsgErr ] = useState('')
  const [ snackType, setSnackType ] = useState('')

  async function getInscricoes(){
    setLoading(true)
    try{
      let res = await api.get('/new-links')

      console.log('res :: ', res.data)

      if(res.status === 200){
        setInscricoes(res.data.users)
      }
    } catch(err){
      setSnackType('error')
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 404){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    } finally {
      setLoading(false)
    }
  }

  async function authorized(id, authorized){
    setLoading(true)
    try {
      let payload = { 
        id,
        authorized,
      }
      let res = await api.put('/authorize-new-course', payload)     
      
      if(res.status === 200){
        setSnackType('success')
        setMsgErr(res.data.message)
        setOpenSnack(true)
      }

    } catch (err) {
      setSnackType('error')
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 404){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getInscricoes()
  }, [])

  return (
    <div id='inscricao-container'>
      { loading&&
        <Loading />
      }
      <Header title={'NOVAS INSCRIÇÕES'} menu={menu} openMenu={openMenu} />
      { inscricoes.length === 0&&
        <div className='emptyList'>Não há nenhuma inscrição para aprovação...</div>
      }
      { inscricoes.map(insc => insc.user_courses.map(course => (
        <NovaInscricaoItem key={course.id} aluno={insc} insc={course} auth={authorized}/>
      )))}
        
      <Menu open={menu} closeMenu={openMenu} adm={true}/> 
      <SnackAlert open={openSnack} setOpen={setOpenSnack} type={snackType} msg={msgErr} />
    </div>
  )
}
