import React from 'react'

import { FiX } from 'react-icons/fi';

import './VacationItem.scss'

export default function VacationItem({item, deletar}) {

  function format(dt){
    return dt.split('-').reverse().join('/')
  }
  
  return (
    <div className='vacationItem'>
      <div className='vacationDate'>{format(item.vacation_start)} á {format(item.vacation_end)}</div>
      <button className='delete' onClick={() => deletar(item)}><FiX className='icon' size={20} color='#ff0000'/></button>
    </div>
  )
}