import React, { useState } from 'react'
import Modal from '@mui/material/Modal'

import * as validations from '../../../utils/validations';

import ErrorMessage from '../../ErrorMessage';
import './CriarFerias.scss'
import DateSelector from '../../DateSelector';

export default function CriarFerias({modal, modalClose, create}) {

  const [ dt_init, setDt_init ] = useState('')
  const [ dt_fim, setDt_fim ] = useState('')
  const [ dateErr, setDateErr ] = useState('')
  
  const [ err, setErr ] = useState({})

  function validate(){
    setDateErr('')
    let err = {}
    if(dt_init !== ''){
      if(validations.date(dt_init.toLocaleDateString('pt-BR'))){
        err.init = validations.date(dt_init.toLocaleDateString('pt-BR'))
      }
    } else {
      err.init = 'Data inicial obrigatória'
    }
    
    if(dt_fim !== ''){  
      if(validations.date(dt_fim.toLocaleDateString('pt-BR'))){
        err.fim = validations.date(dt_fim.toLocaleDateString('pt-BR'))
      }
    } else {
      err.fim = 'Data final obrigatória'
    }
    setErr(err)

    if(JSON.stringify(err) === JSON.stringify({})){
      if(dt_init < dt_fim){
        create(dt_init, dt_fim)
        setDt_init('')
        setDt_fim('')
        setErr({})
      } else {
        setDateErr('Data inicial precisa ser antes da final')
      }
    }
  }

  return (
    <Modal
      open={modal}
      onClose={modalClose}
    >    
      <div id='criarferias-container'>
        <div className='box'>
          Criar Férias
          <div className='nova-data'>
            <div className='data-label'>De:</div>            
            <DateSelector date={dt_init} setDate={setDt_init} place={'Data Inicio'}/>
          </div>
          {err.init&& <ErrorMessage error={err.init} />}
          <div className='nova-data'>
            <div className='data-label'>Até:</div>
            <DateSelector date={dt_fim} setDate={setDt_fim} place={'Data Final'}/>
          </div>
          {err.fim&& <ErrorMessage error={err.fim} />}

          <ErrorMessage error={dateErr} />

          <div className='btnContainer'>
            <button className='cancelar' onClick={() => {
                setDt_init('')
                setDt_fim('')
                setErr({})
                modalClose(false)
              }}
            >
              Cancelar
            </button>
            <button className='confirmar' onClick={() => validate()}>Confirmar</button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
