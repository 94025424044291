import React, {useState, useEffect} from 'react'

import { useNavigate } from 'react-router-dom'
import InputMask from "react-input-mask";

import Header from '../../components/Header'
import Loading from '../../components/Loading'
import Button from '@mui/material/Button';
import ErrorMessage from '../../components/ErrorMessage';
import SnackAlert from '../../components/Alertas/SnackAlert';
import * as validations from '../../utils/validations';

import './EditarPerfil.scss'
import api from '../../service/api'

export default function EditarPerfil(){
  
  const [ id, setId ] = useState('')
  const [ user_courses, setUser_courses ] = useState([])
  const [ adm, setAdm ] = useState(false)
  
  const [ document, setDocument ] = useState('')
  const [ name, setName ] = useState('')
  const [ phone_number, setPhone_number ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ birthday, setBirthday ] = useState('')
  const [ message_notification_email, setMessage_notification_email ] = useState(true)
  const [ message_notification_app, setMessage_notification_app ] = useState(false)
  
  const [ errors, setErrors ] = useState({})

  const [ dateType, setDateType ] = useState('text')
  const [ loading, setLoading ] = useState(false)

  const [ openSnack, setOpenSnack ] = useState(false)
  const [ msgErr, setMsgErr ] = useState('')
  
  const navigate = useNavigate();

  async function handleAtualizar(event){
    event.preventDefault()
    
    let err = {}
    if(validations.name(name)){
      err.name = validations.name(name)
    }
    if(validations.phone(phone_number)){
      err.phone = validations.phone(phone_number)
    }
    if(validations.email(email)){
      err.email = validations.email(email)
    }
    if(validations.date(birthday)){
      err.date = validations.date(birthday)
    }
    setErrors(err)

    if(JSON.stringify(err) === JSON.stringify({})){
      setLoading(true)
      const payload = { 
        name,
        phone_number,
        email,
        birthday: birthday.split('/').reverse().join("-"),
        message_notification_email,
      }
      try {
        let res = await api.put('/user', payload, {
          headers: {
            id: id
          }
        })
  
        if(res.status === 200){
          let user = { 
            id,
            name,
            document,
            email,
            birthday,
            phone_number,
            message_notification_app,
            message_notification_email,
            user_courses,
          }
  
          sessionStorage.setItem('user', JSON.stringify(user))
  
          if(adm){
            navigate('/HomeAdm')
          } else {
            navigate('/HomeUser')
          }
        }
      } catch (err) {
        if(err.message === 'Network Error'){
          setMsgErr('Não foi possível contatar o servidor...')
          setOpenSnack(true)
        }
        let error = err.response
        if(error.status === 400){
          setMsgErr(error.data.message)
          setOpenSnack(true)
        }
        if(error.status === 404){
          setMsgErr(error.data.message)
          setOpenSnack(true)
        }
        if(error.status === 500){
          setMsgErr('Erro ao contatar o servidor, tente novamente')
          setOpenSnack(true)
        }
      } finally {
        setLoading(false)
      }
    }
  
    
  }

  function getUserData(){
    let user = JSON.parse(sessionStorage.getItem('user'))
    setAdm(user.lookup_role_id === 1)
    setId(user.id)
    setUser_courses(user.user_courses)
    setName(user.name)
    setDocument(user.document)
    setEmail(user.email)
    setBirthday(user.birthday.split('-').reverse().join("/"))
    setPhone_number(user.phone_number)
    setMessage_notification_email(user.message_notification_email)
  }

  const maskName = (name) => {
    var loweredText = name.value.toLowerCase()

    var word = loweredText.split(" ")
    
    for(var a= 0; a < word.length; a++){
      var w = word[a]

      var firstLetter = w[0];

      w = firstLetter.toUpperCase() + w.slice(1);

      word[a] = w;
    }

    setName(word.join(" "))
  }

  const maskBirthday = (birth, edit) => {
    if(!edit){
      let value = birth.split('-').reverse().join("/")

      setBirthday(value)

      setDateType('text')
    } else {
      let value = birth.split('/').reverse().join("-")

      setBirthday(value)

      setDateType('date')
    }
  }

  useEffect(() => {
    getUserData()
  }, [])

  return (
    <div id='editprofile-container'>
      { loading&&
        <Loading />
      }
      <Header title={'EDITAR PERFIL'} goBack={true} />
      <div class='formDiv'>
        <form onSubmit={handleAtualizar}>

          <div className='fixedValueCpf'>CPF: {document}</div>

          <input id='name' placeholder='Nome' onBlur={v => maskName(v.target)} value={name} onChange={v => setName(v.target.value)}/>
          {errors.name&& <ErrorMessage error={errors.name} />}
          <InputMask id='phone_number' mask={phone_number.length<14?"(99) 9999-9999":"(99) 99999-9999"} maskPlaceholder='' value={phone_number} placeholder='Telefone' onChange={v => setPhone_number(v.target.value)}/>
          {errors.phone&& <ErrorMessage error={errors.phone} />}
          <input id='email' placeholder='E-mail' type='email' value={email} onChange={v => setEmail(v.target.value)}/>
          {errors.email&& <ErrorMessage error={errors.email} />}
          <input id='birthday' placeholder='Data de Nascimento' type={dateType} onFocus={v => maskBirthday(v.target.value, 1)} onBlur={v => maskBirthday(v.target.value)} value={birthday} onChange={v => setBirthday(v.target.value)} />
          {errors.date&& <ErrorMessage error={errors.date} />}
          

          {adm&&
            <div className='email'>
              <input id='message_notification_email' className='check' type='checkbox' defaultChecked={!message_notification_app} onChange={() => setMessage_notification_email(!message_notification_email)}/>
              Não receber e-mails de avisos de aulas
            </div>
          }

          <Button className='general-button' variant="outlined" type='submit'>Atualizar</Button>
        </form>
      </div>
      <SnackAlert open={openSnack} setOpen={setOpenSnack} type={'error'} msg={msgErr} />
    </div>
  )
}
