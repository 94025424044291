import React, { useState, useEffect } from 'react';

import Calendar from 'react-calendar'
import { differenceInCalendarDays } from 'date-fns';

import Loading from '../../components/Loading';
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import AdmEventCard from '../../components/AdmEventCard';
import Reagendamento from '../../components/Modals/Reagendamento';

import './HomeAdm.scss';

import api from '../../service/api'
import SnackAlert from '../../components/Alertas/SnackAlert';
import feriados from '../../service/feriados';

export default function HomeAdm() {

  const [ date, setDate ] = useState({})
  const weekDays = [
    {
      value: 0,
      label: 'Domingo',
      minLabel: 'Dom',
    },
    {
      value: 1,
      label: 'Segunda-feira',
      minLabel: 'Seg',
    },
    {
      value: 2,
      label: 'Terça-feira',
      minLabel: 'Ter',
    },
    {
      value: 3,
      label: 'Quarta-feira',
      minLabel: 'Qua',
    },
    {
      value: 4,
      label: 'Quinta-feira',
      minLabel: 'Qui',
    },
    {
      value: 5,
      label: 'Sexta-feira',
      minLabel: 'Sex',
    },
    {
      value: 6,
      label: 'Sabado',
      minLabel: 'Sab',
    },
  ]
  const [ events, setEvents ] = useState([])
  const [menu, openMenu] = useState(false)
  const [ dataArray, setDataArray ] = useState([])
  
  const [ modalReagendamento, setModalReagendamento ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ selectedEvent, setSelectedEvent ] = useState('')

  const [ openSnack, setOpenSnack ] = useState(false)
  const [ msgErr, setMsgErr ] = useState('')
  const [ snackType, setSnackType ] = useState('')

  function setDateView(date){

    setEvents([])
   
    weekDays.map(w => {
      //console.log(w.value, d.getUTCDate())
      if(w.value == date.getUTCDay()){
        setDate({
          monthDay: date.getUTCDate(),
          weekDay: w.minLabel,
        })
      }
    })

    let aux = []
    dataArray.map(e => {
      let d =`${e.month_day||e.date}T00:00:00`
      let event = new Date(d)

      if(differenceInCalendarDays(event, date) === 0){
        aux.push(e)
      } 
    })
    setEvents(aux)
  }

  function tileClassName({date, view}){
    if(view === 'month'){
      let e = dataArray.find(event => checkDate(event.month_day || event.date, date))

      if(e){
        if(e.vacation_id){
          return 'vacationTile'
        } else if(e.type === 'national') {
          return 'holidayTile'
        } else if(e.user_course.deleted_at || e.user_course.user.deleted_at) {
          return 'deletedTile'
        } else {
          return 'testeClass'
        }
      }
    }
  }
  function checkDate(e, date) {

    let d = `${e}T00:00:00`

    let event = new Date(d)

    return differenceInCalendarDays(event, date) === 0;
  }

  async function getTeacherEvents(){
    try {
      let year = new Date()
      let array = []

      let id = sessionStorage.getItem('teacherId')

      let res = await api.get('/teacher-events', {
        headers: {
          id: id
        }
      })

      let feriado = await feriados.get(`/${year.getFullYear()}`)

      if(res.status === 200 && feriado.status === 200){
        array = [ ...feriado.data, ...res.data.events ]
        setDataArray(array)
      }
    } catch (err) {
      setMsgErr('error')
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 404){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    } finally {
      setLoading(false)
    }
  }

  async function attendance(id, attendance){
    setLoading(true)
    try {
      const payload = {
        attendance,
      }

      let res = await api.put('/attendance', payload, {
        headers: {
          id: id
        }
      })

      if(res.status === 200){
        getTeacherEvents()
      }
    } catch (err) {
      setSnackType('error')
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response

      console.log('err', error)
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    } finally {
      setLoading(false)
    }
  }

  function openReagendar(event){
    setSelectedEvent(event)
    setModalReagendamento(true)
  }
  
  function successSnack(msg){
    setSnackType('success')
    setMsgErr(msg)
    setOpenSnack(true)
  }

  useEffect(() => {
    setLoading(true)
    setDateView(new Date())
    getTeacherEvents()


  }, [])

  return (
    <div id='homeadm-container'>
      { loading&&
        <Loading />
      }
      <Header title={'AULAS'} menu={menu} openMenu={openMenu} />
      <Menu open={menu} closeMenu={openMenu} adm={true}/> 
      <Reagendamento modal={modalReagendamento} modalClose={setModalReagendamento} event={selectedEvent} success={successSnack} admin={true}/>

      <Calendar 
        calendarType='US'
        value={new Date()}
        onChange={setDateView}
        tileClassName={tileClassName}
      />
      <div className='events'>
        <div className='currentDate'>
          <div className='monthDay'>{date.monthDay}</div>
          <div className='weekDay'>{date.weekDay}</div>
        </div>
        <div className='eventsDate'>
          { events.map(e => (
            <AdmEventCard key={e.id} event={e} att={attendance} reagendar={openReagendar} />
          ))}
        </div>
      </div>
      <SnackAlert open={openSnack} setOpen={setOpenSnack} type={snackType} msg={msgErr} />
    </div>
  )
}
