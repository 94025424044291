import React, {useState, useEffect } from 'react';

import { Link, useNavigate } from 'react-router-dom'
import Calendar from 'react-calendar'
import { differenceInCalendarDays } from 'date-fns';

import Loading from '../../components/Loading'
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import Aviso from '../../components/Modals/Aviso';
import Reagendamento from '../../components/Modals/Reagendamento';
import SnackAlert from '../../components/Alertas/SnackAlert';

import './HomeUser.scss';
import './CalendarStyle.scss';
import api from '../../service/api';
import UserEventCard from '../../components/UserEventCard';
import feriados from '../../service/feriados';

export default function HomeUser() {
  
  const navigate = useNavigate();

  const weekDays = [
    {
      value: 0,
      label: 'Domingo',
      minLabel: 'Dom',
    },
    {
      value: 1,
      label: 'Segunda-feira',
      minLabel: 'Seg',
    },
    {
      value: 2,
      label: 'Terça-feira',
      minLabel: 'Ter',
    },
    {
      value: 3,
      label: 'Quarta-feira',
      minLabel: 'Qua',
    },
    {
      value: 4,
      label: 'Quinta-feira',
      minLabel: 'Qui',
    },
    {
      value: 5,
      label: 'Sexta-feira',
      minLabel: 'Sex',
    },
    {
      value: 6,
      label: 'Sábado',
      minLabel: 'Sab',
    },
  ]

  const [ editEvent, setEditEvent ] = useState('')

  const [ events, setEvents ] = useState([])

  const [ menu, openMenu ] = useState(false)
  const [ aviso, setAviso ] = useState(false)
  const [ modalReagendamento, setModalReagendamento ] = useState(false)
  const [ week_day, setWeek_day ] = useState('')
  
  const [ dataArray, setDataArray ] = useState([])
  
  const [ loading, setLoading ] = useState(false)

  const [ openSnack, setOpenSnack ] = useState(false)
  const [ msgErr, setMsgErr ] = useState('')
  const [ snackType, setSnackType ] = useState('')

  function onChange(date){
    let aux = []

    weekDays.map(w => {
      if(w.value == date.getUTCDay()){
        setWeek_day({
          monthDay: date.getUTCDate(),
          weekDay: w.minLabel,
        })
      }
    })

    dataArray.map(e => {
      let d =`${e.month_day||e.date}T00:00:00`
      let event = new Date(d)

      if(differenceInCalendarDays(event, date) === 0){
        aux.push(e)
      }
    })
    setEvents(aux)
  }

  function reagendar(e){
    setEditEvent(e)
    setModalReagendamento(true)
  }

  function tileClassName({date, view}){
    if(view === 'month'){
      let e = dataArray.find(event => checkDate(event.month_day || event.date, date))

      if(e){
        if(e.vacation_id){
          return 'vacationTile'
        } else if(e.type === 'national') {
          return 'holidayTile'
        } else {
          return 'testeClass'
        }
      }
    }
  }
  function checkDate(e, date) {

    let d = `${e}T00:00:00`

    let event = new Date(d) 

    return differenceInCalendarDays(event, date) === 0;
  }

  function getCourses(){
    let user = JSON.parse(sessionStorage.getItem('user'))

    let aux = []
    user.user_courses.map(uc => {
      aux.push(uc.id)
    })

    return aux
    
  }

  async function getEvents(){
    try {
      let year = new Date()
      setLoading(true)
      let user_courses = getCourses()

      let aux = []
      
      let feriado = await feriados.get(`/${year.getFullYear()}`)

      for (const uc of user_courses){
        let res = await api.get('/events', {
          headers: {
            id: uc
          }
        })
        if(res.status === 200){

          for (const e of res.data.events){
            aux.push(e)
          }
        }
      }


      if(feriado.status === 200){
        aux = [ ...feriado.data, ...aux ]
      }

      setDataArray(aux)

    } catch (err) {
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 404){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    } finally {
      setLoading(false)
    }
  }

  async function openAviso(){
    let aviso = sessionStorage.getItem('openAviso')

    if(aviso === 'true'){
      setAviso(true)
    }

    sessionStorage.removeItem('openAviso')
  }

  function successSnack(msg){
    setSnackType('success')
    setMsgErr(msg)
    setOpenSnack(true)
  }

  useEffect(() => {
    openAviso()
    getEvents()
  }, [])

  return (
    <div id="HomeUser-container">
      { loading&&
        <Loading />
      }
      <Header title={'REAGENDAMENTO'} menu={menu} openMenu={openMenu} />
      <Menu open={menu} closeMenu={openMenu} openAviso={setAviso}/> 
      <Aviso modal={aviso} modalClose={setAviso}/>
      <Reagendamento modal={modalReagendamento} modalClose={setModalReagendamento} event={editEvent} success={successSnack}/>
      
      <Calendar 
        calendarType='US'
        value={new Date()}
        onChange={onChange}
        tileClassName={tileClassName}
      />
      <div className='events'>
        <div className='currentDate'>
          <div className='monthDay'>{week_day.monthDay}</div>
          <div className='weekDay'>{week_day.weekDay}</div>
        </div>
        <div className='detailsContainer'>
          { events.map((e) => (
            <UserEventCard event={e} reagendar={reagendar}/>
          ))}
        </div>
      </div>
      <SnackAlert open={openSnack} setOpen={setOpenSnack} type={snackType} msg={msgErr} />
    </div>
  )
}
