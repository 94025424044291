import React, {useState, useEffect} from 'react'
import { FiInfo } from 'react-icons/fi'
import './NovoCurso.scss'

import Header from '../../components/Header'
import Loading from '../../components/Loading'
import ErrorMessage from '../../components/ErrorMessage'
import SnackAlert from '../../components/Alertas/SnackAlert';

import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom'
import api from '../../service/api';
import DetalhesCurso from '../../components/Modals/DetalhesCurso'
import * as validations from '../../utils/validations';
import formatarHorario from '../../utils/formatarHorario';

export default function AlterarCurso() {

  const navigate = useNavigate();

  const [ user_id, setUser_id ] = useState('')
  const [ day, setDay ] = useState('')
  const [ teacher_id, setTeacher_id ] = useState('')
  const [ course_id, setCourse_id ] = useState('')
  const [ hour_id, setHour_id ] = useState('')

  const [ errors, setErrors ] = useState({})
  
  const [ cursosProfessores, setCursosProfessores ] = useState([])
  const [ hours, setHours ] = useState([])
  const [ curso, setCurso ] = useState('')
  const [ detalhesCurso, setDetalhesCurso ] = useState(false)
  const [ loading, setLoading ] = useState(false)

  const [ openSnack, setOpenSnack ] = useState(false)
  const [ msgErr, setMsgErr ] = useState('')

  async function handleNewLink(event){
    event.preventDefault()
    let err = {}
    if(validations.selectCourse(teacher_id, course_id)){
      err.selectCourse = validations.selectCourse(teacher_id, course_id)
    }
    if(validations.id(day) && course_id !== ''){
      err.weekday = 'Necessário selecionar um dia da semana'
    }
    if(validations.id(hour_id) && day !== ''){
      err.hour = 'Necessário selecionar um horário'
    }
    setErrors(err)

    if(JSON.stringify(err) === JSON.stringify({})){
      setLoading(true)
      try{
        let payload = {
          user_id,
          course_id,
          teacher_id,
          day,
          hour_id,
        }

        let res = await api.post('/link', payload)

        if(res.status === 200){
          navigate('/HomeUser')
        }

      } catch(err) {
        if(err.message === 'Network Error'){
          setMsgErr('Não foi possível contatar o servidor...')
          setOpenSnack(true)
        }
        let error = err.response
        if(error.status === 400){
          setMsgErr(error.data.message)
          setOpenSnack(true)
        }
        if(error.status === 500){
          setMsgErr('Erro ao contatar o servidor, tente novamente')
          setOpenSnack(true)
        }
      } finally {
        setLoading(false)
      }
    }

  }

  function getUser(){
    let id = sessionStorage.getItem('userId')

    setUser_id(id)
  }

  async function getProfessores(){
    setLoading(true)
    try {
      let res = await api.get('/teacher')

      if(res.status === 200){
        setCursosProfessores(res.data.teachers)
      }
    } catch (err){
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    }finally{
      setLoading(false)
    }
  }
  
  async function getHours(){
    setLoading(true)
    try {
      let res = await api.get('/workingHours', {
        headers: {
          id: teacher_id
        }
      })

      if(res.status === 200){
        setHours(res.data)
      }
    } catch (err) {
      if(err.message === 'Network Error'){
        setMsgErr('Não foi possível contatar o servidor...')
        setOpenSnack(true)
      }
      let error = err.response
      if(error.status === 400){
        setMsgErr(error.data.message)
        setOpenSnack(true)
      }
      if(error.status === 500){
        setMsgErr('Erro ao contatar o servidor, tente novamente')
        setOpenSnack(true)
      }
    } finally{
      setLoading(false)
    }
  }

  function manageTeacherCourse(values){
    setDay('')
    let ids = values.split('|')
    let course = JSON.parse(ids[1])

    setCurso(course.course)
    setTeacher_id(parseInt(ids[0]))
    setCourse_id(course.course_id)
    getHours()
  }

  useEffect(() => {
    getUser()
    getProfessores()
  },[])

  return (
    <div id='novocurso-container'>

      { loading&&
        <Loading />
      }
      <DetalhesCurso modal={detalhesCurso} modalClose={setDetalhesCurso} curso={curso} />
      <Header title={'NOVO CURSO'} goBack={true} />
      
      <div className='title'>Selecione um novo curso para se inscrever:</div>
      <div className='formDiv'>
        <form onSubmit={handleNewLink}>
          <select onChange={v => manageTeacherCourse(v.target.value)}>
            <option value="" hidden defaultValue>Curso</option>
            { cursosProfessores.map(prf => prf.user_courses.map( c => (
              <option key={c.id} value={`${prf.id}|${JSON.stringify(c)}`}>{`${prf.name} - ${c.course.name}`}</option>
            )))}
          </select>
          {errors.selectCourse&& <ErrorMessage error={errors.selectCourse} />}

          { curso&&
            <div onClick={() => setDetalhesCurso(true)} className='courseDetails'>
              <FiInfo className='icon' size={16} color={'#262b44'}/>
              Informações do curso
            </div>
          }

          <div className='weekday'>
            { course_id !== ''&&
              <>
                <div onClick={() => setDay(1)} className={`dayItem ${ day === 1&& 'dayItem-selected'}`}>Seg</div>
                <div onClick={() => setDay(2)} className={`dayItem ${ day === 2&& 'dayItem-selected'}`}>Ter</div>
                <div onClick={() => setDay(3)} className={`dayItem ${ day === 3&& 'dayItem-selected'}`}>Qua</div>
                <div onClick={() => setDay(4)} className={`dayItem ${ day === 4&& 'dayItem-selected'}`}>Qui</div>
                <div onClick={() => setDay(5)} className={`dayItem ${ day === 5&& 'dayItem-selected'}`}>Sex</div>
                <div onClick={() => setDay(6)} className={`dayItem ${ day === 6&& 'dayItem-selected'}`}>Sab</div>
              </>
            }
          </div>
          {errors.weekday&&course_id !== ''&& <ErrorMessage error={errors.weekday} />}

          <div className='time'>
            { hours.map(h => h.week_day === day&&(
              <>
                { h.hours.map(hour => (
                  <>
                    { hour.is_available&&
                      <div key={hour.id} onClick={() => setHour_id(hour.id)} className={`timeStamp ${ hour_id === hour.id&& 'timeStamp-selected'}`}>{formatarHorario(hour.from_hour)}</div>
                    }
                  </>
                ))}
              </>
            ))}
          </div>
          {errors.hour&&day!==''&& <ErrorMessage error={errors.hour} />}

          <Button className='general-button' variant="outlined" type='submit'>Solicitar inscrição</Button>
        </form>
      </div>
      <SnackAlert open={openSnack} setOpen={setOpenSnack} type={'error'} msg={msgErr} />
    </div>
  )
}
